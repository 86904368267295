import React from 'react'
import './styles.css'
import {
  CATEGORIES,
  CATEGORIES_LABEL,
} from '@san4uru/front-utils/dist/constants'
import classnames from 'classnames'
import { Checkbox } from '../../components'

const AspectsCheckboxSet = ({
  onChange,
  checkedHashMap = {},
  className = '',
  error = true,
}) => (
  <React.Fragment>
    <div
      className={classnames(
        'aspects-cbx-set',
        { 'aspects-cbx-set_error': !!error },
        className
      )}>
      {Object.values(CATEGORIES)
        .filter(Boolean)
        .map((category) => (
          <Checkbox
            label={CATEGORIES_LABEL[category]}
            key={category}
            checked={!!checkedHashMap[category]}
            onChange={(e) => onChange(e, category)}
          />
        ))}
    </div>
    <br />
  </React.Fragment>
)
export default AspectsCheckboxSet
