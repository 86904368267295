import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import TextField from '@material-ui/core/TextField'
import { bindActionCreators } from 'redux'
import {
  createNews,
  fetchExactNews,
  clearCurrentNews,
  editNews,
  saveAsDraft,
} from '../../modules/news'
import './styles.css'
import { goBack } from 'connected-react-router'
import {
  CreatorScreenFooter,
  Title,
  Subtitle,
  ErrorDescription,
  Checkbox,
  Showcase,
  ShowcaseItem,
  // DateTimePicker,
  UploadAttachmentButton,
} from '../../components'
import isEqual from 'lodash-es/isEqual'
import { ErrorDescriptionType } from '../../components/ErrorDescription'
import { getDateTimeMS } from '../../components/DateTimePicker'
import moment from 'moment'
import { getParamFromUrl } from '@san4uru/front-utils/dist/utils'

class NewsCreator extends React.Component {
  state = {
    name: '',
    description: '',
    images: [],
    created: '',
    active: true,

    mainImage: {},
    meta: {
      Title: '',
      Description: '',
      Keywords: '',
    },
  }

  componentDidMount() {
    if (this.props.newsId && this.props.isEdit) {
      this.props
        .fetchExactNews(this.props.newsId)
        .then(({ created, ...data }) =>
          this.setState({
            mainImage: data.images[0] || {},
            ...data,
            created: moment(created * 1000).format(),
          })
        )
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentNews()
  }

  onFileUploadChange = (file) => {
    this.setState(({ images }) => {
      if (!images.length) {
        this.onSetMainImage(file)
      }

      return { images: [...images, file] }
    })
  }

  onCancel = () => {
    const { description, images, name } = this.state

    if (!this.props.isEdit) {
      this.props.saveAsDraft({ description, name, images })
    }
    this.props.goBack()
  }

  onSave = () => {
    const { mainImage, images: stateImages, ...state } = this.state
    if (!this.isCanSave()) {
      return
    }

    let images = stateImages.slice()
    const i = mainImage.url
      ? stateImages.findIndex(({ url }) => url === mainImage.url)
      : -1

    if (i !== -1) {
      images = [
        mainImage,
        ...stateImages.slice(0, i),
        ...stateImages.slice(i + 1),
      ]
    }

    const created = getDateTimeMS(state.created)
    this.props.isEdit
      ? this.props.editNews({ ...state, created, images })
      : this.props.createNews({ ...state, created, images })
  }

  isCanSave = () => {
    const { name, description, images } = this.state

    return (
      name.length &&
      description.length &&
      images.length &&
      (this.props.isEdit ? !isEqual(this.state, this.props.currentNews) : true)
    )
  }

  onImageRemove = (imageUrl) => {
    const { images = [] } = this.state
    const index = images.findIndex(({ url }) => url === imageUrl)

    if (index === -1) return

    this.setState({
      images: [...images.slice(0, index), ...images.slice(index + 1)],
    })
  }

  onSetMainImage = (mainImage) => {
    this.setState({ mainImage })
  }

  renderErrorDescription = () => {
    if (this.props.isEdit && isEqual(this.state, this.props.currentNews)) {
      return <ErrorDescription type={ErrorDescriptionType.SameContent} />
    }

    if (!this.isCanSave()) {
      const { name, description, images } = this.state
      const fields = [
        !name.length ? 'name' : undefined,
        !description.length ? 'description' : undefined,
        !images.length ? 'images' : undefined,
      ]
      return (
        <ErrorDescription
          type={ErrorDescriptionType.EmptyFields}
          fields={fields.filter(Boolean)}
        />
      )
    }

    return null
  }
  formFieldChangeHandler = ({ target }) => {
    this.setState({meta: {
      ...this.state.meta,
      [target.name] : target.value,
    }})
  }
 
  render() {
    const { isEdit = false } = this.props
  const { name, description, images, active, meta = {} /*created */} = this.state

    return (
      <div className="news-creator">
        <Title>{isEdit ? 'Редактирование новости' : 'Создание новости'}</Title>
        {this.renderErrorDescription()}
        {/*<div className="news-creator__created">*/}
        {/*  <DateTimePicker*/}
        {/*    value={created}*/}
        {/*    onChange={(created) => this.setState({ created })}*/}
        {/*  />*/}
        {/*</div>*/}
        <br />
        <div className="news-creator__main-info">
          <TextField
            id="name"
            className="news-creator__name"
            label="Название"
            error={!name.length}
            required
            value={name}
            autoComplete="off"
            autoFocus
            onChange={({ target }) => this.setState({ name: target.value })}
          />
          <Checkbox
            checked={active}
            label="Отображать на сайте"
            onChange={({ target }) => this.setState({ active: target.checked })}
          />
        </div>
        <br />
        <TextField
          id="name"
          label="Описание"
          error={!description.length}
          required
          value={description}
          multiline
          rows={6}
          autoComplete="off"
          onChange={({ target }) =>
            this.setState({ description: target.value })
          }
        />
        <br />
        <h2>Заполните мета-данные</h2>
          <TextField
            id="Title"
            label="title"
            value={meta.Title}
            name='Title'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Description"
            label="description"
            value={meta.Description}
            name='Description'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Keywords"
            label="keywords"
            value={meta.Keywords}
            name='Keywords'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
        <div className="news-creator__images">
          <Subtitle>Изображения</Subtitle>
          <UploadAttachmentButton
            multiple
            onFileUploadChange={this.onFileUploadChange}
          />
          <div className="news-creator__images__list">
            <Showcase
              onRemoveAll={() => this.setState({ images: [] })}
              gallery={images}
              onRemove={this.onImageRemove}
              customItemRender={(image) => (
                <ShowcaseItem
                  key={image.url}
                  image={image}
                  onRemove={() => this.onImageRemove(image.url)}
                  onSetMainImage={() => this.onSetMainImage(image)}
                  isMainImage={
                    image.url && image.url === this.state.mainImage.url
                  }
                />
              )}
            />
          </div>
        </div>
        <CreatorScreenFooter
          onCancel={this.onCancel}
          onSave={this.onSave}
          isSaveDisabled={!this.isCanSave()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ news }) => {
  return { currentNews: news.current, newsId: getParamFromUrl('news_id') }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNews,
      editNews,
      fetchExactNews,
      goBack,
      saveAsDraft,
      clearCurrentNews,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsCreator)
)
