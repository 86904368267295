import React from 'react'
import './styles.css'
import { DateTimePicker as DateTimePickerMUI } from '@material-ui/pickers'
import moment from 'moment'

let ruLocale = require('moment/locale/ru')
moment.locale('ru', ruLocale)

export const getDateTimeMS = (ISOdate) => new Date(ISOdate).getTime() / 1000

const DateTimePicker = ({ label = 'Создано', error, onChange, value }) => (
  <DateTimePickerMUI
    disableFeature
    showTodayButton
    ampm={false}
    label={label}
    className="date-time-picker__field"
    placeholder={label}
    onChange={(date) => onChange(date.format())}
    value={value}
  />
)
export default DateTimePicker
