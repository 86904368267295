import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './styles.css'
import TextField from '@material-ui/core/TextField'
import { push, goBack } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import {
  fetchExactBrand,
  createBrand,
  resetBrandsState,
  editBrand,
  saveAsDraft,
  clearCurrentBrandState,
} from '../../modules/brands'
import { CATEGORIES } from '@san4uru/front-utils/dist/constants'
import { getParamFromUrl } from '@san4uru/front-utils/dist/utils'
import { uploadImage } from '../../modules/attachments'
import classnames from 'classnames'
import {
  CreatorScreenFooter,
  AspectsCheckboxSet,
  ErrorDescription,
  Subtitle,
  Title,
  UploadAttachmentButton,
  Checkbox,
} from '../../components'
import isEqual from 'lodash-es/isEqual'
import DeleteIcon from '@material-ui/icons/Delete'
import { ErrorDescriptionType } from '../../components/ErrorDescription'
import { resetGlobalState } from '../../modules/global'

const defaultAspects = {
  [CATEGORIES.TILE]: false,
  [CATEGORIES.SANITARY]: false,
  [CATEGORIES.SAUNA]: false,
  [CATEGORIES.STONE]: false,
  [CATEGORIES.HEATING]: false,
  [CATEGORIES.MOSAIC]: false,
}

class BrandsCreator extends React.Component {
  state = {
    aspects: {
      ...defaultAspects,
    },
    name: '',
    description: '',
    active: true,
    logo: '',
    url: '',
    meta: {
      Title: '',
      Description: '',
      Keywords: '',
    },
  }

  componentDidMount() {
    if (this.props.isEdit && this.props.brandId) {
      this.props
        .fetchExactBrand(this.props.brandId)
        .then(({ aspects: originalAspects, ...brand }) => {
          const aspects = { ...defaultAspects }
          originalAspects.map((aspect) => (aspects[aspect] = true))
          this.setState({
            aspects,
            ...brand,
          })
        })
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentBrandState()
    this.props.resetGlobalState()
    this.timer && clearTimeout(this.timer)
  }

  get isChanged() {
    const { aspects: _, ...brandWithoutAspectsFromState } = this.state
    const { aspects: aspectsFromProps, ...brandWithoutAspectsFromProps } =
      this.props.currentBrand

    return (
      !isEqual(brandWithoutAspectsFromState, brandWithoutAspectsFromProps) ||
      !isEqual(this.getCurrentAspects(), aspectsFromProps)
    )
  }

  get isCanSave() {
    const { name, logo } = this.state

    return (
      name.length &&
      this.getCurrentAspects().length &&
      logo &&
      (this.props.isEdit ? this.isChanged : true)
    )
  }

  getCurrentAspects = () => {
    const { aspects } = this.state
    return Object.keys(aspects).filter((aspectName) => !!aspects[aspectName])
  }

  onAspectsChange = ({ target }, category) => {
    let { aspects } = this.state

    aspects[category] = target.checked
    this.setState({ aspects })
  }

  onSave = () => {
    const { isEdit } = this.props
    const aspects = this.getCurrentAspects()
    if (!this.isCanSave) {
      return
    }

    if (isEdit) {
      this.props.editBrand({ ...this.state, aspects })
      return
    }

    this.props.createBrand({ ...this.state, aspects })
  }

  onCancel = () => {
    const { name, description } = this.state

    if (!this.props.isEdit) {
      this.props.saveAsDraft({
        aspects: this.getCurrentAspects(),
        name,
        description,
      })
    }
    this.props.goBack()
  }

  formFieldChangeHandler = ({ target }) => {
    this.setState({meta: {
      ...this.state.meta,
      [target.name] : target.value,
    }})
  }

  renderMainBlock = () => {
    const { name, description, logo, meta = {}} = this.state

    return (
      <div className="brands-creator__main">
        <div
          className={classnames(
            'brands-creator__main-logo',
            `brands-creator__main-logo_${logo ? 'existed' : 'error'}`
          )}>
          {!!logo && (
            <React.Fragment>
              <div
                title="Удалить лого"
                className="brands-creator__main-logo__delete"
                onClick={() => this.setState({ logo: '' })}>
                <DeleteIcon />
              </div>
              <img src={logo} alt="Логотип бренда" />
            </React.Fragment>
          )}
        </div>
        <div className="brands-creator__main-description">
          <TextField
            id="name"
            label="Название"
            required
            error={name.length === 0}
            value={name}
            autoComplete="off"
            autoFocus
            onChange={({ target }) => 
              this.setState({ name: target.value })
          }
          />
          <br />
          <TextField
            id="description"
            label="Описание"
            value={description}
            multiline
            rows={6}
            autoComplete="off"
            onChange={({ target }) =>
              this.setState({ description: target.value })
            }
          />
          <br />
          <h2>Заполните мета-данные</h2>
          <TextField
            id="Title"
            label="title"
            value={meta.Title}
            name='Title'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Description"
            label="description"
            value={meta.Description}
            name='Description'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Keywords"
            label="keywords"
            value={meta.Keywords}
            name='Keywords'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <div className="brands-creator__main-logo__upload">
            <UploadAttachmentButton
              onFileUploadChange={({ url: logo }) => this.setState({ logo })}
            />
          </div>
        </div>
      </div>
    )
  }

  renderErrorDescription = () => {
    if (this.props.isEdit && !this.isChanged) {
      return <ErrorDescription type={ErrorDescriptionType.SameContent} />
    }

    if (!this.isCanSave) {
      const { name, logo } = this.state
      const fields = [
        !name.length ? 'name' : undefined,
        !logo ? 'logo' : undefined,
        !this.getCurrentAspects().length ? 'aspects' : undefined,
      ]

      return (
        <ErrorDescription
          type={ErrorDescriptionType.EmptyFields}
          fields={fields.filter(Boolean)}
        />
      )
    }

    return null
  }

  render() {
    const { isEdit = false, currentBrand } = this.props

    return (
      <div className="brands-creator">
        <Title>
          {isEdit
            ? `Редактирование бренда ${currentBrand.name}`
            : 'Создание бренда'}
        </Title>
        {this.renderErrorDescription()}
        <div className="brands-creator__content">
          {this.renderMainBlock()}
          <br />
          <Subtitle>Категория</Subtitle>
          <AspectsCheckboxSet
            error={!this.getCurrentAspects().length}
            checkedHashMap={this.state.aspects}
            onChange={this.onAspectsChange}
          />
          <Checkbox
            label="Сразу отображать активным на сайте"
            checked={!!this.state.active}
            onChange={({ target }) => this.setState({ active: target.checked })}
          />
          <br />
        </div>
        <CreatorScreenFooter
          onCancel={this.onCancel}
          onSave={this.onSave}
          isSaveDisabled={!this.isCanSave}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ brands }) => ({
  currentBrand: brands.current,
  brandId: getParamFromUrl('brand_id'),
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      editBrand,
      resetBrandsState,
      createBrand,
      fetchExactBrand,
      saveAsDraft,
      clearCurrentBrandState,
      uploadImage,
      goBack,
      resetGlobalState,
    },
    dispatch
  )
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandsCreator)
)
