import React from 'react'
import './styles.css'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { login, yandexOauthUrl } from '../../modules/auth'
import { LOCAL_ROUTES } from '../../constants/routes'
import { Spinner } from '../../components/Loader'
import { ROUTES, BASE_URL } from '@san4uru/front-utils/dist/constants'

class Auth extends React.Component {
  state = { signingIn: false }

  componentDidMount() {
    if (this.props.auth.email) {
      this.props.push(ROUTES.BRANDS)
    }

    if (window.location.pathname.includes(LOCAL_ROUTES.OAUTH)) {
      this.setState({ signingIn: true })
      this.props.login().then(() => this.setState({ signingIn: false }))
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      window.location.pathname.includes(LOCAL_ROUTES.OAUTH)
    ) {
      this.setState({ signingIn: true })
      this.props.login().then(() => this.setState({ signingIn: false }))
    }
  }

  onLogin = () => {
    if (this.state.signingIn) return

    window.open(yandexOauthUrl(), '_self')
  }

  render() {
    const { signingIn } = this.state

    return (
      <Container className="auth" component="main" maxWidth="xs">
        <div className="auth__paper">
          {signingIn ? (
            <Spinner />
          ) : (
            <Avatar className="auth__avatar">
              <LockOutlinedIcon />
            </Avatar>
          )}
          <Button
            type="submit"
            fullWidth
            onClick={this.onLogin}
            variant="contained"
            color={signingIn ? 'default' : 'primary'}
            disabled={signingIn}
            className="auth__submit">
            Войти с помощью Яндекс
          </Button>
        </div>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={BASE_URL.PROD}>
              Европейская Сантехника
            </Link>{' '}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    )
  }
}
const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, login }, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth))
