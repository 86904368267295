import React from 'react'
import './styles.css'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const PageHeaderActions = ({ className, onAdd, onReload, addLabel = '' }) => (
  <div className="page-header-actions">
    <Button
      variant="outlined"
      color="primary"
      startIcon={<AddIcon />}
      onClick={onAdd}>
      {addLabel || 'Добавить'}
    </Button>
    <Button variant="outlined" color="default" onClick={onReload}>
      Обновить список
    </Button>
  </div>
)
export default PageHeaderActions
