import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import classnames from 'classnames'

const SelectList = ({
  className = '',
  label = '',
  onChange,
  value,
  options = [],
  optionLabels = {},
  error = false,
}) => (
  <FormControl
    className={classnames(
      'select-list',
      { 'select-list_error': error },
      className
    )}>
    <InputLabel>{label}</InputLabel>
    <Select
      error={!!error}
      className="select-list__field"
      value={value}
      onChange={onChange}>
      {options.map((o) => (
        <MenuItem key={o} value={o}>{optionLabels[o] || o}</MenuItem>
      ))}
    </Select>
  </FormControl>
)
export default SelectList
