import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import * as History from 'history'
import { auth } from './auth'
import { brands } from './brands'
import { news } from './news'
import { items } from './items'
import { projects } from './projects'
import { promo } from './promo'
import { attachments } from './attachments'
import { global } from './global'
import { brandCollection } from './brand_collection'

export const history = History.createBrowserHistory()

export default combineReducers({
  auth,
  attachments,
  brands,
  brandCollection,
  items,
  global,
  news,
  projects,
  promo,
  router: connectRouter(history),
})
