import { createAction, handleActions } from 'redux-actions'
import {
  fetchExactItem as fetchExactItemRest,
  fetchItems as fetchItemsListRest,
  createItem as createItemRest,
  editItem as editItemRest,
  convertPrice as convertPriceRest,
  deleteItem as deleteItemRest,
} from '../services/items'
import { goBack } from 'connected-react-router'
import { addAlert, AlertType } from './global'

export const FETCH_ITEMS_LIST = createAction('FETCH_ITEMS_LIST')
export const FETCH_EXACT_ITEM = createAction('FETCH_EXACT_ITEM')
export const SAVE_ITEM_AS_DRAFT = createAction('SAVE_ITEM_AS_DRAFT')
export const UPDATE_CURRENT_ITEM_STATE = createAction(
  'UPDATE_CURRENT_ITEM_STATE'
)
export const UPDATE_ITEMS_STATE = createAction('UPDATE_ITEMS_STATE')
export const RESET_ITEMS_STATE = createAction('RESET_ITEMS_STATE')

export const fetchItemsList = (params = {}) => (dispatch, getState) => {
  const { page = 0, query } = params

  return fetchItemsListRest({ page, query })
    .then(({ data }) => {
      if (page === 0) {
        dispatch(FETCH_ITEMS_LIST(data.items))
        dispatch(
          UPDATE_ITEMS_STATE({
            page,
            hasNextPage:
              !getState().items.list.length ||
              getState().items.list.length < data.total,
            needForceUpdate: false,
          })
        )

        return { page, list: data.items }
      }

      dispatch(
        UPDATE_ITEMS_STATE({
          page,
          list: [...getState().items.list, ...data.items],
          hasNextPage: getState().items.list.length < data.total,
        })
      )

      return { page, list: data.items }
    })
    .catch((err) => console.error(err))
}

export const fetchExactItem = (id) => (dispatch) => {
  return fetchExactItemRest({ id })
    .then(({ data }) => {
      dispatch(FETCH_EXACT_ITEM(data))
      return data
    })
    .catch((err) => console.error(err))
}

export const createItem = (item) => (dispatch) => {
  dispatch(SAVE_ITEM_AS_DRAFT(item))

  return createItemRest({ ...item, brand: item.brand.url })
    .then(({ data }) => {
      dispatch(SAVE_ITEM_AS_DRAFT(baseItemProps))
      dispatch(UPDATE_ITEMS_STATE({ needForceUpdate: true }))
      dispatch(goBack())

      dispatch(
        addAlert({ text: 'Товар успешно создан', type: AlertType.Success })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.log(err)
    })
}

export const editItem = (item) => (dispatch) => {
  return editItemRest({ ...item, brand: item.brand.url })
    .then(({ data }) => {
      dispatch(UPDATE_ITEMS_STATE({ needForceUpdate: true }))
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'Товар успешно отредактирован',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const deleteItem = ({ id }) => (dispatch) => {
  return deleteItemRest({ id })
    .then(({ data }) => {
      dispatch(UPDATE_ITEMS_STATE({ needForceUpdate: true }))

      dispatch(
        addAlert({
          text: 'Товар успешно удален',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const convertPrice = ({ currency, price }) => () => {
  return convertPriceRest({ currency, price })
    .then(({ data }) => data)
    .catch((err) => console.error(err))
}

export const saveAsDraft = (item) => (dispatch) =>
  dispatch(SAVE_ITEM_AS_DRAFT(item))
export const resetItemsState = () => (dispatch) => dispatch(RESET_ITEMS_STATE())
export const clearCurrentItem = () => (dispatch) =>
  dispatch(UPDATE_CURRENT_ITEM_STATE(baseItemProps))

const baseItemProps = {
  brand: {},
  old_price: 0,
  category: '',
  id: undefined,
  aspect: '',
  name: '',
  alt_name: '',
  description: '',
  type: '',
  images: [],
  full_description: '',
  price: 0,
}

const initialState = {
  list: [],
  page: 0,
  hasNextPage: true,
  needForceUpdate: false,
  current: { ...baseItemProps },
  draft: { ...baseItemProps },
}

export const items = handleActions(
  {
    [FETCH_ITEMS_LIST]: (state, { payload }) => ({
      ...state,
      list: [...payload],
    }),
    [FETCH_EXACT_ITEM]: (state, { payload }) => ({
      ...state,
      current: payload,
    }),
    [UPDATE_CURRENT_ITEM_STATE]: (state, { payload }) => ({
      ...state,
      current: payload,
    }),
    [SAVE_ITEM_AS_DRAFT]: (state, { payload }) => ({
      ...state,
      draft: payload,
    }),
    [UPDATE_ITEMS_STATE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [RESET_ITEMS_STATE]: () => initialState,
  },
  initialState
)
