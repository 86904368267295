import { createAction, handleActions } from 'redux-actions'
import {
  fetchProjects as fetchProjectsRest,
  fetchExactProject as fetchExactProjectRest,
  createProject as createProjectRest,
  editProject as editProjectRest,
  deleteProject as deleteProjectRest,
} from '../services/projects'
import { goBack } from 'connected-react-router'
import { addAlert, AlertType } from './global'

export const FETCH_PROJECTS_LIST = createAction('FETCH_PROJECTS_LIST')
export const FETCH_EXACT_PROJECT = createAction('FETCH_EXACT_PROJECT')
export const SAVE_PROJECT_AS_DRAFT = createAction('SAVE_PROJECT_AS_DRAFT')
export const UPDATE_PROJECT_STATE = createAction('UPDATE_PROJECT_STATE')
export const UPDATE_CURRENT_PROJECT_STATE = createAction(
  'UPDATE_CURRENT_PROJECT_STATE'
)
export const RESET_PROJECTS_STATE = createAction('RESET_PROJECTS_STATE')

export const fetchProjectsList = (params = {}) => (dispatch, getState) => {
  const { page = 0 } = params

  return fetchProjectsRest({ page })
    .then(({ data }) => {
      if (page === 0) {
        dispatch(FETCH_PROJECTS_LIST(data.projects))
        dispatch(
          UPDATE_PROJECT_STATE({
            page,
            hasNextPage:
              !getState().projects.list.length ||
              getState().projects.list.length < data.total,
            needForceUpdate: false,
          })
        )

        return { page, list: data.projects }
      }

      dispatch(
        UPDATE_PROJECT_STATE({
          list: [...getState().projects.list, ...data.projects],
          page,
          hasNextPage: getState().projects.list.length < data.total,
        })
      )

      return { page, list: data.projects }
    })
    .catch((err) => console.error(err))
}

export const fetchExactProject = (id) => (dispatch) => {
  return fetchExactProjectRest({ id })
    .then(({ data }) => {
      dispatch(FETCH_EXACT_PROJECT(data))
      return data
    })
    .catch((err) => console.error(err))
}

export const createProject = (project) => (dispatch) => {
  dispatch(SAVE_PROJECT_AS_DRAFT(project))

  return createProjectRest(project)
    .then(({ data: id }) => {
      dispatch(SAVE_PROJECT_AS_DRAFT(baseProjectProps))
      dispatch(UPDATE_PROJECT_STATE({ needForceUpdate: true }))
      dispatch(goBack())

      dispatch(
        addAlert({ text: 'Проект успешно создан', type: AlertType.Success })
      )

      return id
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const editProject = (project) => (dispatch) => {
  return editProjectRest(project)
    .then(({ data }) => {
      dispatch(UPDATE_PROJECT_STATE({ needForceUpdate: true }))
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'Проект успешно отредактирован',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const deleteProject = ({ id }) => (dispatch) => {
  return deleteProjectRest({ id })
    .then(({ data }) => {
      dispatch(UPDATE_PROJECT_STATE({ needForceUpdate: true }))

      dispatch(
        addAlert({
          text: 'Проект успешно удален',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const saveAsDraft = (project) => (dispatch) =>
  dispatch(SAVE_PROJECT_AS_DRAFT(project))
export const resetProjectsState = () => (dispatch) =>
  dispatch(RESET_PROJECTS_STATE())
export const clearCurrentProject = () => (dispatch) =>
  dispatch(UPDATE_CURRENT_PROJECT_STATE(baseProjectProps))

const baseProjectProps = {
  id: undefined,
  name: '',
  text: '',
  catalog: [],
  author: '',
}
const initialState = {
  list: [],
  page: 0,
  hasNextPage: true,
  needForceUpdate: false,
  current: { ...baseProjectProps },
  draft: { ...baseProjectProps },
}
export const projects = handleActions(
  {
    [FETCH_PROJECTS_LIST]: (state, { payload }) => ({
      ...state,
      list: [...payload],
    }),
    [FETCH_EXACT_PROJECT]: (state, { payload }) => ({
      ...state,
      current: payload,
    }),
    [SAVE_PROJECT_AS_DRAFT]: (state, { payload }) => ({
      ...state,
      draft: payload,
    }),
    [UPDATE_CURRENT_PROJECT_STATE]: (state, { payload }) => ({
      ...state,
      current: payload,
    }),
    [UPDATE_PROJECT_STATE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [RESET_PROJECTS_STATE]: () => initialState,
  },
  initialState
)
