import React from 'react'
import { withRouter } from 'react-router'
import { contacts, ROUTES } from '@san4uru/front-utils/dist/constants'
import { ContactBlock } from '@san4uru/front-utils/dist/components'
import { AskDevelopersText, Title } from '../../components'
import './styles.css'
import { constructUrl } from '@san4uru/front-utils/dist/utils'

class Contacts extends React.Component {
  render() {
    return (
      <div className="contacts">
        <div className="contacts__header">
          <Title>
            Текущие контакты, указанные на{' '}
            <a href={constructUrl({ pathname: ROUTES.CONTACT })}>сайте</a>
            <AskDevelopersText />
          </Title>
        </div>

        <div className="contacts__content">
          {contacts.filter((el)=> el.title !== "Салон на Фрунзенской").map((item) => (
            <ContactBlock key={item.value} {...item} />
          ))}
        </div>
      </div>
    )
  }
}

export default withRouter(Contacts)
