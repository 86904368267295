import React from 'react'
import debounce from 'lodash-es/debounce'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { LOCAL_ROUTES } from '../../constants/routes'
import { fetchNewsList, RESET_NEWS_STATE, deleteNews } from '../../modules/news'
import {
  Title,
  PageHeaderActions,
  SearchInput,
  Loader,
  LoadMore,
  ListItem,
  EmptySection,
} from '../../components'
import { navTo } from '../../utils/html'
import './styles.css'
import { constructUrl } from '@san4uru/front-utils/dist/utils'
import { formatDate, getShortText } from '@san4uru/front-utils/dist/utils'
import { ROUTES, TEXT_LENGTH } from '@san4uru/front-utils/dist/constants'

class News extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isLoading: !props.news.list.length, textSearch: '' }

    this.onSearchDebounced = debounce(this.onSearch, 300, { leading: false })
  }

  componentDidMount() {
    if (!this.props.news.list.length) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.news.needForceUpdate && this.props.news.needForceUpdate) {
      this.onReloadNewsList()
    }
  }

  loadData = (params = {}) => {
    const { page, query } = params

    this.setState({ isLoading: true })
    this.props
      .fetchNewsList({ page, query })
      .then(() => this.setState({ isLoading: false }))
  }

  onReloadNewsList = () => {
    this.props.resetNewsList()
    this.loadData()
  }

  onLoadNextPage = () => {
    this.loadData({
      page: this.props.news.page + 1,
      query: this.state.textSearch || undefined,
    })
  }

  onSearch = (textSearch) => {
    this.setState({ textSearch })
    this.loadData({ query: textSearch })
  }

  onRemove = (id) => {
    this.props.deleteNews({ id })
  }

  render() {
    const { list, hasNextPage } = this.props.news
    const { isLoading, textSearch } = this.state

    if (!list.length && !isLoading) {
      return (
        <div className="news">
          <PageHeaderActions
            addLabel="Добавить новость"
            onAdd={() => this.props.push(LOCAL_ROUTES.NEWS_CREATE)}
            onReload={this.onReloadNewsList}
          />
          <Title>Последние новости:</Title>
          {textSearch && (
            <SearchInput
              onSearch={this.onSearch}
              initialValue={textSearch}
              onChange={this.onSearchDebounced}
            />
          )}
          <EmptySection className="empty-screen" />
        </div>
      )
    }

    return (
      <div className="news">
        <PageHeaderActions
          addLabel="Добавить новость"
          onAdd={() => this.props.push(LOCAL_ROUTES.NEWS_CREATE)}
          onReload={this.onReloadNewsList}
        />
        <Title>Последние новости:</Title>
        <SearchInput
          onSearch={this.onSearch}
          initialValue={textSearch}
          onChange={(textSearch) => this.setState({ textSearch })}
        />
        <div className="news__content">
          {list.map(({ id, images, created, name, description, active }) => (
            <ListItem
              key={id}
              logo={images[0] && images[0].url}
              content={
                <React.Fragment>
                  <span>
                    <b>Название:</b> {name}
                  </span>
                  <span>
                    <b>Создано:</b>{' '}
                    <i>
                      <small>{formatDate(created)}</small>
                    </i>
                  </span>
                  <br />
                  <span>
                    {getShortText(description, {
                      maxTextLength: TEXT_LENGTH.ALL_NEWS,
                    })}
                  </span>
                  {!active ? (
                    <React.Fragment>
                      <br />
                      <span className="text-warning">
                        <b>Не отображается на сайте</b>
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              }
              onEdit={() =>
                navTo({
                  pathname: LOCAL_ROUTES.NEWS_EDIT,
                  params: { news_id: id },
                })
              }
              onRemove={() => this.onRemove(id)}
              itemLink={constructUrl({ pathname: `${ROUTES.NEWS}/${id}` })}
            />
          ))}
        </div>
        {isLoading && <Loader />}
        {hasNextPage && <LoadMore onClick={this.onLoadNextPage} />}
      </div>
    )
  }
}

const mapStateToProps = ({ news }) => ({ news })
const mapDispatchToProps = (dispatch) => {
  const resetNewsList = () => dispatch(RESET_NEWS_STATE)

  return bindActionCreators(
    {
      push,
      fetchNewsList,
      resetNewsList,
      deleteNews,
    },
    dispatch
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News))
