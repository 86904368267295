import { createAction, handleActions } from 'redux-actions'
import {
  createNews as createNewsRest,
  editNews as editNewsRest,
  fetchNewsList as fetchNewsListRest,
  fetchExactNews as fetchExactNewsRest,
  deleteNews as deleteNewsRest,
} from '../services/news'
import { goBack } from 'connected-react-router'
import { addAlert, AlertType } from './global'

export const FETCH_NEWS_LIST = createAction('FETCH_NEWS_LIST')
export const UPDATE_CURRENT_NEWS = createAction('UPDATE_CURRENT_NEWS')
export const UPDATE_NEWS_STATE = createAction('UPDATE_NEWS_STATE')
export const UPDATE_DRAFT_NEWS = createAction('UPDATE_DRAFT_NEWS')
export const RESET_NEWS_STATE = createAction('RESET_NEWS_STATE')

export const createNews = (news) => (dispatch) => {
  dispatch(UPDATE_DRAFT_NEWS(news))

  return createNewsRest(news)
    .then(({ data }) => {
      dispatch(UPDATE_NEWS_STATE({ needForceUpdate: true }))
      dispatch(goBack())

      dispatch(
        addAlert({ text: 'Новость успешно создана', type: AlertType.Success })
      )
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const editNews = (news) => (dispatch) => {
  dispatch(UPDATE_DRAFT_NEWS(news))

  return editNewsRest(news)
    .then(({ data }) => {
      dispatch(UPDATE_NEWS_STATE({ needForceUpdate: true }))
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'Новость успешно отредактирована',
          type: AlertType.Success,
        })
      )
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const deleteNews = ({ id }) => (dispatch) => {
  return deleteNewsRest({ id })
    .then(() => {
      dispatch(UPDATE_NEWS_STATE({ needForceUpdate: true }))
      dispatch(
        addAlert({
          text: 'Новость успешно удалена',
          type: AlertType.Success,
        })
      )

      return
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const fetchNewsList = (params = {}) => (dispatch, getState) => {
  const { page = 0, query } = params

  return fetchNewsListRest({ page, query })
    .then(({ data }) => {
      if (page === 0) {
        dispatch(FETCH_NEWS_LIST({ list: data.news }))
        dispatch(
          UPDATE_NEWS_STATE({
            page,
            hasNextPage:
              !getState().news.list.length ||
              getState().news.list.length < data.total,
            needForceUpdate: false,
          })
        )

        return { page, list: data.news }
      }

      dispatch(
        UPDATE_NEWS_STATE({
          list: [...getState().news.list, ...data.news],
          page,
          hasNextPage: getState().news.list.length < data.total,
        })
      )
      return { page, list: data.news }
    })
    .catch((err) => console.log(err))
}

export const fetchExactNews = (id) => (dispatch) => {
  return fetchExactNewsRest({ id })
    .then(({ data }) => {
      dispatch(UPDATE_CURRENT_NEWS(data))
      return data
    })
    .catch((err) => console.error(err))
}

export const saveAsDraft = (draft) => (dispatch) =>
  dispatch(UPDATE_DRAFT_NEWS(draft))
export const clearCurrentNews = () => (dispatch) =>
  dispatch(UPDATE_CURRENT_NEWS({ description: '', name: '', images: [] }))

const initialNewsState = {
  name: '',
  description: '',
  active: true,
  created: 0,
  id: undefined,
  images: [
    {
      url: '',
      name: '',
      type: '',
    },
  ],
}
const initialState = {
  draft: {
    ...initialNewsState,
  },
  current: {
    ...initialNewsState,
  },
  list: [],
  page: 0,
  hasNextPage: true,
  needForceUpdate: false,
}

export const news = handleActions(
  {
    [FETCH_NEWS_LIST]: (state, { payload: { list } }) => ({
      ...state,
      list,
    }),
    [UPDATE_CURRENT_NEWS]: (state, { payload }) => ({
      ...state,
      current: payload,
    }),
    [UPDATE_DRAFT_NEWS]: (state, { payload }) => ({
      ...state,
      draft: payload,
    }),
    [UPDATE_NEWS_STATE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [RESET_NEWS_STATE]: () => initialState,
  },
  initialState
)
