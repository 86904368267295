import React from 'react'
import './styles.css'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import debounce from 'lodash-es/debounce'

export default class SearchInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = { inputValue: props.initialValue || '' }
    this.onSearchDebounced = debounce(props.onSearch, 500)
  }

  onChangeValue = (value) => {
    this.setState({ inputValue: value })
    this.props.onChange(value)
    this.onSearchDebounced(value)
  }

  render() {
    const { inputValue } = this.state

    return (
      <div className="search-input">
        <Input
          fullWidth
          autoFocus
          placeholder="Поиск"
          onChange={({ target }) => this.onChangeValue(target.value)}
          value={inputValue}
        />
        <Button
          className="search-input__btn"
          color="primary"
          variant="contained"
          onClick={() => {
            inputValue.length && this.onSearchDebounced(inputValue)
          }}>
          Найти
        </Button>
      </div>
    )
  }
}
