import React from 'react'
import './styles.css'

export const Spinner = () => (
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
)

export const Loader = () => (
  <div className="loader">
    <Spinner />
  </div>
)
