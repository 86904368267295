import React from 'react'

export const ImageInput = ({ multiple, label, id, onChange }) => (
  <React.Fragment>
    <input
      type="file"
      id={id}
      className="upload-attachment-button__input"
      accept="image/jpeg, image/png, image/gif"
      multiple={!!multiple}
      onChange={onChange}
    />
    {label}
  </React.Fragment>
)
