import { ROUTES } from '@san4uru/front-utils/dist/constants'

export const LOCAL_ROUTES = {
  NEWS_CREATE: `${ROUTES.NEWS}/create`,
  NEWS_EDIT: `${ROUTES.NEWS}/edit`,
  BRANDS_CREATE: `${ROUTES.BRANDS}/create`,
  BRANDS_EDIT: `${ROUTES.BRANDS}/edit`,
  BRAND_COLLECTIONS: `${ROUTES.BRANDS}/collections`,
  BRANDS_CREATE_COLLECTION: `${ROUTES.BRANDS}/create_collection`,
  BRANDS_EDIT_COLLECTION: `${ROUTES.BRANDS}/edit_collection`,
  BRAND_HISTORY: `${ROUTES.BRANDS}/history`,
  BRANDS_CREATE_HISTORY: `${ROUTES.BRANDS}/create_history`,
  BRANDS_EDIT_HISTORY: `${ROUTES.BRANDS}/edit_history`,
  ITEM: '/item',
  ITEMS: '/items',
  ITEMS_CREATE: '/items/create',
  ITEMS_EDIT: '/items/edit',
  LOGIN: '/login',
  OAUTH: '/oauth',
  PROJECTS_CREATE: `${ROUTES.PROJECTS}/create`,
  PROJECTS_EDIT: `${ROUTES.PROJECTS}/edit`,
  PROMO: '/promo',
  PROMO_CREATE: '/promo/create',
  PROMO_EDIT: '/promo/edit',
}
