import React from 'react'

export const PdfInput = ({ label, id, onChange }) => (
  <React.Fragment>
    <input
      type="file"
      id={id}
      className="upload-attachment-button__input"
      accept="application/pdf"
      onChange={onChange}
    />
    {label}
  </React.Fragment>
)
