import React from 'react'
import './styles.css'
import { Showcase as BaseShowcase } from '@san4uru/front-utils/dist/components'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button } from '@material-ui/core'

const Showcase = ({
  gallery = [],
  onRemove,
  additionalAction,
  onAdditionalActionClick,
  customItemRender,
  onRemoveAll,
}) => (
  <React.Fragment>
    {gallery.length ? (
      <Button
        className="showcase__remove-all"
        color="primary"
        variant="contained"
        onClick={onRemoveAll}>
        Удалить все изображения
      </Button>
    ) : null}
    <BaseShowcase
      removeButton={
        <div title="Удалить" className="showcase-item__remove">
          <DeleteIcon className="showcase-item__remove__icon" />
        </div>
      }
      onRemoveButtonClick={onRemove}
      additionalAction={additionalAction}
      onAdditionalActionClick={onAdditionalActionClick}
      gallery={gallery}
      customItemRender={customItemRender}
    />
  </React.Fragment>
)
export default Showcase
