import { createAction, handleActions } from 'redux-actions'
import { auth as authRest, userInfo as userInfoRest } from '../services/auth'
import { ROUTES } from '@san4uru/front-utils/dist/constants'
import { push } from 'connected-react-router'
import { getToken } from '../utils/html'
import { isTestEnv } from '@san4uru/front-utils/dist/utils'

export const LOGIN = createAction('LOGIN')
export const RESET = createAction('RESET')

export const yandexOauthUrl = () => {
  const url = new URL('https://oauth.yandex.ru/authorize')
  const params = {
    response_type: 'token',
    client_id: isTestEnv()
      ? '699f01dc4efc46f9b786739880dfb0eb'
      : '572ca0b88600431dbf0159a1d5453222',
  }

  url.search = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')

  return url
}

export const login = () => (dispatch) => {
  return authRest({ token: getToken() })
    .then(({ data }) => dispatch(LOGIN, data))
    .then(() => dispatch(push(ROUTES.BRANDS)))
    .catch((err) => console.error(err))
}

export const userInfo = () => (dispatch) => {
  return userInfoRest()
    .then((data) => {
      dispatch(LOGIN(data))
      return data
    })
    .catch((err) => console.error(err))
}

const initialState = {
  id: 0,
  email: '',
  admin: false,
}

export const auth = handleActions(
  {
    [LOGIN]: (state, { payload: { id, email, admin } }) => ({
      ...state,
      id,
      email,
      admin,
    }),
    [RESET]: () => initialState,
  },
  initialState
)
