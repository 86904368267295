import React from 'react'
import './styles.css'
import { push, goBack } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  CreatorScreenFooter,
  ErrorDescription,
  SelectList,
  Subtitle,
  Title,
  Showcase,
  UploadAttachmentButton,
  ShowcaseItem,
  Checkbox,
} from '../../components'
import {
  createCollection,
  editCollection,
  fetchBrandCollection,
  resetBrandCollectionState,
} from '../../modules/brand_collection'
import { fetchExactBrand } from '../../modules/brands'
import {
  CATEGORIES,
  CATEGORIES_LABEL,
} from '@san4uru/front-utils/dist/constants'
import { getParamFromUrl } from '@san4uru/front-utils/dist/utils'
import TextField from '@material-ui/core/TextField'
import isEqual from 'lodash-es/isEqual'
import { ErrorDescriptionType } from '../../components/ErrorDescription'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FileType } from '../../components/UploadAttachmentButton/FileType'
import { resetGlobalState } from '../../modules/global'

class BrandCollectionCreator extends React.Component {
  state = {
    id: undefined,
    aspect: '',
    name: '',
    active: true,
    colorImages: [], // same as catalogs[0] in tile-template brand
    interierImages: [], // same as catalogs[1] in tile-template brand
    mainImages: [], // same as catalogs[0] in common brand
    pdf: '',
    catalogs: [],

    isMonoCollection: false,
  }

  componentDidMount() {
    const { collectionId } = this.props

    if (this.props.isEdit) {
      this.props
        .fetchBrandCollection(collectionId)
        .then(({ catalogs, ...data }) => {
          const isMonoCollection = catalogs.length === 1

          this.setState({
            ...data,
            colorImages: !isMonoCollection ? catalogs[0] : [],
            interierImages: !isMonoCollection ? catalogs[1] : [],
            mainImages: isMonoCollection ? catalogs[0] : [],
            isMonoCollection,
          })
        })
    }
  }

  componentWillUnmount() {
    this.props.resetBrandCollectionState()
    this.props.resetGlobalState()
  }

  onCancel = () => {
    this.props.goBack()
  }

  onSave = () => {
    if (!this.isCanSave()) {
      return
    }

    const { brandId } = this.props
    const {
      isMonoCollection,
      interierImages = [],
      colorImages = [],
      mainImages = [],
      ...state
    } = this.state
    let catalogs = isMonoCollection
      ? [mainImages]
      : [colorImages, interierImages]

    if (this.props.isEdit) {
      this.props.editCollection({ ...state, catalogs, brandId })

      return
    }
    this.props.createCollection({ ...state, catalogs, brandId })
  }

  isCanSave = () => {
    const {
      isMonoCollection,
      colorImages,
      mainImages,
      interierImages,
      ...state
    } = this.state
    const { name, aspect } = state
    const hasImages = isMonoCollection
      ? mainImages.length
      : colorImages.length || interierImages.length

    return (
      name.length &&
      aspect &&
      hasImages &&
      (this.props.isEdit ? !isEqual(state, this.props.brandCollection) : true)
    )
  }

  onToggleImagesRender = ({ target }) => {
    const isMonoCollection = target.checked

    if (isMonoCollection) {
      this.setState(({ interierImages = [] }) => ({
        isMonoCollection: true,
        mainImages: interierImages,
        interierImages: [],
      }))

      return
    }

    this.setState(({ mainImages = [] }) => ({
      isMonoCollection: false,
      mainImages: [],
      interierImages: mainImages,
    }))
  }

  onUploadMainImage = (image) => {
    this.setState(({ mainImages = [] }) => ({
      mainImages: [...mainImages, image],
    }))
  }

  onUploadColorImage = (image) => {
    this.setState(({ colorImages = [] }) => ({
      colorImages: [...colorImages, image],
    }))
  }

  onUploadInterierImage = (image) => {
    this.setState(({ interierImages = [] }) => ({
      interierImages: [...interierImages, image],
    }))
  }

  renderColorImages = () => {
    const { colorImages } = this.state

    return (
      <div className="brand-collection-creator__images__content-block">
        <Subtitle>Цвета:</Subtitle>
        <UploadAttachmentButton
          elementUid="colors"
          multiple
          onFileUploadChange={this.onUploadColorImage}
        />
        {colorImages.length ? (
          <div className="brand-collection-creator__images__content-block_horizontal">
            <Showcase
              onRemoveAll={() => this.setState({ colorImages: [] })}
              gallery={colorImages}
              customItemRender={(image) => (
                <ShowcaseItem
                  key={image.url}
                  image={image}
                  shouldRenderMainImageCheckbox={false}
                  onRemove={() =>
                    this.setState(({ colorImages = [] }) => ({
                      colorImages: colorImages.filter(
                        ({ url }) => url !== image.url
                      ),
                    }))
                  }
                />
              )}
            />
          </div>
        ) : null}
      </div>
    )
  }

  renderInterierImages = ({ images, onRemove, onUpload, onRemoveAll }) => {
    return (
      <React.Fragment>
        <Subtitle>Фото в интерьере:</Subtitle>
        <UploadAttachmentButton
          elementUid="main"
          multiple
          onFileUploadChange={onUpload}
        />
        {images.length ? (
          <Showcase
            onRemoveAll={onRemoveAll}
            gallery={images}
            customItemRender={(image) => (
              <ShowcaseItem
                key={image.url}
                image={image}
                shouldRenderMainImageCheckbox={false}
                onRemove={() => onRemove(image.url)}
              />
            )}
          />
        ) : null}
      </React.Fragment>
    )
  }

  renderImages = () => {
    const { isMonoCollection, mainImages, interierImages } = this.state
    const header = (
      <div className="brand-collection-creator__images__header">
        <Title>Изображения</Title>
        <FormControlLabel
          control={
            <Switch
              checked={isMonoCollection}
              onChange={this.onToggleImagesRender}
            />
          }
          label="Отображать только фото в интерьере"
        />
      </div>
    )

    if (isMonoCollection) {
      return (
        <div className="brand-collection-creator__images">
          {header}
          <div className="brand-collection-creator__images__content">
            {this.renderInterierImages({
              images: mainImages,
              onUpload: this.onUploadMainImage,
              onRemove: (imageUrl) =>
                this.setState(({ mainImages = [] }) => ({
                  mainImages: mainImages.filter(({ url }) => url !== imageUrl),
                })),
              onRemoveAll: () => this.setState({ mainImages: [] }),
            })}
          </div>
        </div>
      )
    }

    return (
      <div className="brand-collection-creator__images">
        {header}
        <div className="brand-collection-creator__images__content">
          {this.renderColorImages()}
          <div className="brand-collection-creator__images__content-block">
            {this.renderInterierImages({
              images: interierImages,
              onUpload: this.onUploadInterierImage,
              onRemove: (imageUrl) =>
                this.setState(({ interierImages = [] }) => ({
                  interierImages: interierImages.filter(
                    ({ url }) => url !== imageUrl
                  ),
                })),
              onRemoveAll: () => this.setState({ interierImages: [] }),
            })}
          </div>
        </div>
      </div>
    )
  }

  renderErrorDescription = () => {
    const {
      isMonoCollection,
      interierImages,
      mainImages,
      colorImages,
      ...state
    } = this.state

    if (this.props.isEdit && isEqual(state, this.props.brandCollection)) {
      return <ErrorDescription type={ErrorDescriptionType.SameContent} />
    }

    if (!this.isCanSave()) {
      const hasImages = isMonoCollection
        ? mainImages.length
        : colorImages.length || interierImages.length
      const { name, aspect } = this.state
      const fields = [
        !name.length ? 'name' : undefined,
        !aspect.length ? 'aspect' : undefined,
        !hasImages ? 'images' : undefined,
      ]
      return (
        <ErrorDescription
          type={ErrorDescriptionType.EmptyFields}
          fields={fields.filter(Boolean)}
        />
      )
    }

    return null
  }

  renderCatalog = () => {
    const { pdf } = this.state

    return (
      <React.Fragment>
        <Subtitle
          className="brand-collection-creator__catalog-title"
          materialUIProps={{ variant: 'subtitle1' }}>
          Каталог
        </Subtitle>
        <div className="brand-collection-creator__catalog">
          {pdf && (
            <a href={pdf} rel="noopener noreferrer" target="_blank">
              {pdf}
            </a>
          )}
          <UploadAttachmentButton
            elementUid="pdf-uploader"
            fileType={FileType.Pdf}
            onFileUploadChange={(file) => this.setState({ pdf: file.url })}
            label={pdf ? 'Загрузить новый PDF' : 'Загрузить PDF'}
          />
        </div>
        <br />
      </React.Fragment>
    )
  }

  render() {
    const { isEdit, brandId } = this.props
    const { aspect, name } = this.state

    return (
      <div className="brand-collection-creator">
        <Title>
          {isEdit
            ? 'Редактирование коллекции бренда'
            : 'Создание коллекции бренда'}
        </Title>
        {this.renderErrorDescription()}
        <div className="brand-collection-creator__content">
          <div className="brand-collection-creator__main-info">
            <TextField
              className="brand-collection-creator__name"
              id="name"
              label="Название"
              required
              value={name}
              error={!name.length}
              autoComplete="off"
              autoFocus
              onChange={({ target }) => this.setState({ name: target.value })}
            />
            <Checkbox
              label="Сразу отображать активным на сайте"
              checked={!!this.state.active}
              onChange={({ target }) =>
                this.setState({ active: target.checked })
              }
            />
          </div>
          <br />
          <SelectList
            label="Категория"
            error={!aspect}
            optionLabels={CATEGORIES_LABEL}
            options={Object.values(CATEGORIES).filter(Boolean)}
            value={aspect}
            onChange={({ target }) => this.setState({ aspect: target.value })}
          />
          <br />
          {this.renderCatalog()}
          <TextField
            id="brand"
            label="ИД бренда"
            value={brandId}
            autoComplete="off"
            disabled
          />
          <br />
          {this.renderImages()}
        </div>
        <CreatorScreenFooter
          onCancel={this.onCancel}
          onSave={this.onSave}
          isSaveDisabled={!this.isCanSave()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ brandCollection }) => ({
  brandCollection: brandCollection.current,
  brandId: getParamFromUrl('brand_id'),
  collectionId: getParamFromUrl('collection_id'),
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      goBack,
      editCollection,
      createCollection,
      fetchBrandCollection,
      fetchExactBrand,
      resetBrandCollectionState,
      resetGlobalState,
    },
    dispatch
  )
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandCollectionCreator)
)

