import React from 'react'
import './styles.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push, goBack } from 'connected-react-router'
import { CreatorScreenFooter, Title } from '../../components'
import { getParamFromUrl } from '@san4uru/front-utils/dist/utils'

class PromoCreator extends React.Component {
  onSave = () => {}
  onCancel = () => {
    this.props.goBack()
  }
  isCanSave = () => {
    return true
  }
  render() {
    const { isEdit, promo } = this.props

    return (
      <div className="promo-creator">
        <Title>
          {isEdit
            ? `Редактирование промо-кампании ${promo.name}`
            : 'Создание промо-кампании'}
        </Title>
        <CreatorScreenFooter
          onSave={this.onSave}
          onCancel={this.onCancel}
          isSaveDisabled={!this.isCanSave}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ promo }) => {
  return { promo: promo.current, promoId: getParamFromUrl('promo_id') }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, goBack }, dispatch)
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PromoCreator)
)
