import * as React from 'react'
import { hot } from 'react-hot-loader/root'
import './styles.css'
import { Accordeon, AppBar } from '../../components'
import { Route, Switch, Redirect, withRouter } from 'react-router'
import { ROUTES } from '@san4uru/front-utils/dist/constants'
import { LOCAL_ROUTES } from '../../constants/routes'
import classnames from 'classnames'
import '@san4uru/front-utils/dist/styles.css'
import Auth from '../Auth'
import News from '../News'
import NewsCreator from '../NewsCreator'
import Contacts from '../Contacts'
import Brands from '../Brands'
import BrandsCreator from '../BrandsCreator'
import BrandHistoryCreator from '../BrandHistoryCreator'
import BrandCollectionCreator from '../BrandCollectionCreator'
import BrandCollections from '../BrandCollections'
import Projects from '../Projects'
import ProjectsCreator from '../ProjectsCreator'
import Promo from '../Promo'
import Items from '../Items'
import ItemsCreator from '../ItemsCreator'
import BrandHistory from '../BrandHistory'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PromoCreator from '../PromoCreator'
import PopupsContainer from '../PopupsContainer'
import AlertsContainer from '../AlertsContainer'

class App extends React.Component {
  render() {
    return (
      <div
        className={classnames('app', {
          'app-login': [LOCAL_ROUTES.LOGIN, LOCAL_ROUTES.OAUTH].includes(
            window.location.pathname
          ),
        })}>
        <AppBar />
        <div className="app-wrapper">
          <Accordeon />
          <AlertsContainer />
          <PopupsContainer />
          <div className="app-content_wrapper">
            <div className="app-content" id="app-content">
              <Switch>
                <Route
                  exact
                  path={[LOCAL_ROUTES.LOGIN, LOCAL_ROUTES.OAUTH]}
                  render={Auth}
                />
                <Route exact path={ROUTES.NEWS} render={News} />
                <Route
                  exact
                  path={[LOCAL_ROUTES.NEWS_CREATE, LOCAL_ROUTES.NEWS_EDIT]}
                  render={() => (
                    <NewsCreator
                      isEdit={
                        window.location.pathname === LOCAL_ROUTES.NEWS_EDIT
                      }
                    />
                  )}
                />
                <Route exact path={ROUTES.BRANDS} render={Brands} />
                <Route
                  exact
                  path={[LOCAL_ROUTES.BRANDS_CREATE, LOCAL_ROUTES.BRANDS_EDIT]}
                  render={() => (
                    <BrandsCreator
                      isEdit={
                        window.location.pathname === LOCAL_ROUTES.BRANDS_EDIT
                      }
                    />
                  )}
                />
                <Route
                  exact
                  path={LOCAL_ROUTES.BRAND_HISTORY}
                  render={() => <BrandHistory />}
                />
                <Route
                  exact
                  path={[
                    LOCAL_ROUTES.BRANDS_CREATE_HISTORY,
                    LOCAL_ROUTES.BRANDS_EDIT_HISTORY,
                  ]}
                  render={() => (
                    <BrandHistoryCreator
                      isEdit={
                        window.location.pathname ===
                        LOCAL_ROUTES.BRANDS_EDIT_HISTORY
                      }
                    />
                  )}
                />
                <Route
                  exact
                  path={LOCAL_ROUTES.BRAND_COLLECTIONS}
                  render={BrandCollections}
                />
                <Route
                  exact
                  path={[
                    LOCAL_ROUTES.BRANDS_CREATE_COLLECTION,
                    LOCAL_ROUTES.BRANDS_EDIT_COLLECTION,
                  ]}
                  render={() => (
                    <BrandCollectionCreator
                      isEdit={
                        window.location.pathname ===
                        LOCAL_ROUTES.BRANDS_EDIT_COLLECTION
                      }
                    />
                  )}
                />
                <Route exact path={ROUTES.PROJECTS} render={Projects} />
                <Route
                  exact
                  path={[
                    LOCAL_ROUTES.PROJECTS_CREATE,
                    LOCAL_ROUTES.PROJECTS_EDIT,
                  ]}
                  render={() => (
                    <ProjectsCreator
                      isEdit={
                        window.location.pathname === LOCAL_ROUTES.PROJECTS_EDIT
                      }
                    />
                  )}
                />
                <Route exact path={LOCAL_ROUTES.ITEMS} render={Items} />
                <Route
                  exact
                  path={[LOCAL_ROUTES.ITEMS_CREATE, LOCAL_ROUTES.ITEMS_EDIT]}
                  render={() => (
                    <ItemsCreator
                      isEdit={
                        window.location.pathname === LOCAL_ROUTES.ITEMS_EDIT
                      }
                    />
                  )}
                />
                <Route exact path={LOCAL_ROUTES.PROMO} render={Promo} />
                <Route
                  exact
                  path={[LOCAL_ROUTES.PROMO_CREATE, LOCAL_ROUTES.PROMO_EDIT]}
                  render={() => (
                    <PromoCreator
                      isEdit={
                        window.location.pathname === LOCAL_ROUTES.PROMO_EDIT
                      }
                    />
                  )}
                />
                <Route exact path={ROUTES.CONTACTS} render={Contacts} />
                <Redirect from="*" to={ROUTES.BRANDS} />
              </Switch>
            </div>

            {[
              ROUTES.CONTACTS,
              ROUTES.PROJECTS,
              ROUTES.BRANDS,
              LOCAL_ROUTES.ITEMS,
              LOCAL_ROUTES.BRAND_COLLECTIONS,
              LOCAL_ROUTES.BRAND_HISTORY,
              ROUTES.NEWS,
            ].includes(window.location.pathname) ? (
              <a href="#app-content" className="app__scroll-to-top">
                <ExpandLessIcon />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(hot(App))
