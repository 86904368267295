import { createAction, handleActions } from 'redux-actions'
import {
  uploadImage as uploadImageRest,
  uploadPdf as uploadPdfRest,
} from '../services/attachments'
import { addAlert, AlertType } from './global'

export const UPLOAD_ATTACHMENT = createAction('UPLOAD_ATTACHMENT')

export const uploadImage = ({ file }) => (dispatch) => {
  return uploadImageRest({ file, type: 'image' })
    .then(({ data }) => {
      dispatch(UPLOAD_ATTACHMENT(data))
      return data
    })
    .catch((err) => {
      console.error(err)
      if (err === 'IMAGE_TOO_LARGE') {
        dispatch(
          addAlert({
            title: `Не удалось загрузить фото ${file.name}`,
            text: 'Фото слишком большое, его размер не должен превышать 20 МБ',
            type: AlertType.Error,
          })
        )
      } else {
        dispatch(
          addAlert({
            title: 'Произошла ошибка',
            text: `Не удалось загрузить фото ${file.name}`,
            type: AlertType.Error,
          })
        )
      }

      return { isError: true, error: err }
    })
}

export const uploadPdf = ({ file }) => (dispatch) => {
  return uploadPdfRest({ file, type: 'pdf' })
    .then(({ data }) => {
      dispatch(UPLOAD_ATTACHMENT(data))
      return data
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: `Не удалось загрузить файл ${file.name}`,
          type: AlertType.Error,
        })
      )

      return { isError: true, error: err }
    })
}

const initialState = {
  list: [],
}
export const attachments = handleActions(
  {
    [UPLOAD_ATTACHMENT]: (state, { payload }) => ({
      ...state,
      list: [...state.list, payload],
    }),
  },
  initialState
)
