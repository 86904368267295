import React from 'react'
import './styles.css'
import classnames from 'classnames'
import MaterialCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const Checkbox = ({
  className = '',
  checked = false,
  onChange,
  label = '',
  error = false,
}) => (
  <FormControlLabel
    className={classnames('checkbox', className, {
      checkbox_error: error,
    })}
    control={
      <MaterialCheckbox
        color="primary"
        checked={!!checked}
        onChange={onChange}
      />
    }
    label={label}
  />
)
export default Checkbox
