import React from 'react'
import './styles.css'
import { Typography } from '@material-ui/core'
import classnames from 'classnames'

const Title = ({ children, className }) => (
  <Typography variant="h6" className={classnames('title', className)}>
    {children}
  </Typography>
)
export default Title
