import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'
import './styles.css'
import { addPopup } from '../../modules/global'
import CreateIcon from '@material-ui/icons/Create'
import Button from '@material-ui/core/Button'
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp'
import DeleteIcon from '@material-ui/icons/Delete'

const ListItem = ({
  logo,
  content,
  className,
  onEdit,
  itemLink,
  additionButtons,
  onRemove,

  addPopup
}) => {
  const created = useMemo(() => Date.now(), [])
  const handleRemove = () => {
    addPopup({ text: 'Уверены, что хотите удалить?', title: 'Удаление', created, onAccept: onRemove })
  }

  return (
    <div className={classnames('list-item', className)}>
      <div
        className={classnames('list-item__logo', {
          'list-item__logo_not-found': !logo,
        })}>
        {logo ? (
          <img src={logo} alt={logo ? 'logo' : ''} />
        ) : (
          <HighlightOffSharpIcon />
        )}
      </div>
      <div className="list-item__content">{content}</div>
      <div className="list-item__actions">
        {onRemove && (
          <Button
            className="list-item__action"
            variant="contained"
            color="secondary"
            onClick={handleRemove}
            startIcon={<DeleteIcon />}>
            Удалить
          </Button>
        )}
        {onEdit && (
          <Button
            className="list-item__action"
            variant="contained"
            color="primary"
            onClick={onEdit}
            startIcon={<CreateIcon />}>
            Редактировать
          </Button>
        )}
        {itemLink && (
          <Button color="default" size="small" className="list-item__action">
            <a href={itemLink} target="_blank" rel="noopener noreferrer">
              Перейти на страницу
            </a>
          </Button>
        )}
        {additionButtons}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addPopup }, dispatch)

export default connect(null, mapDispatchToProps)(ListItem)
