import React from 'react'
import './styles.css'
import {
  getImageThumbnailUrl,
  THUMBNAIL_SIZE,
} from '@san4uru/front-utils/dist/utils'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DeleteIcon from '@material-ui/icons/Delete'

const ShowcaseItem = ({
  image: { url, name },
  onRemove,
  shouldRenderMainImageCheckbox = true,
  isMainImage,
  onSetMainImage,
}) => (
  <div
    key={url}
    data-fancybox="showcase_4"
    className="mt-15 mt-md-30 col-lg-4 col-md-6 col-12 d-block link w-full overflow-hidden showcase-item">
    <img
      srcSet={`${getImageThumbnailUrl(url, THUMBNAIL_SIZE.SMALL)} 2x`}
      src={getImageThumbnailUrl(url, THUMBNAIL_SIZE.SMALL)}
      className="radius6 img-fluid w-full"
      alt={name}
    />
    {name && (
      <div className="showcase-item__title">
        <span>{name}</span>
      </div>
    )}
    <div title="Удалить" className="showcase-item__remove" onClick={onRemove}>
      <DeleteIcon className="showcase-item__remove__icon" />
    </div>
    {shouldRenderMainImageCheckbox ? (
      <div className="news-creator__image__cbx">
        <FormControlLabel
          label="Главное фото"
          onClick={onSetMainImage}
          control={
            <Radio
              color="primary"
              checked={isMainImage}
              onChange={onSetMainImage}
            />
          }
        />
      </div>
    ) : null}
  </div>
)
export default ShowcaseItem
