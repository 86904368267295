import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './styles.css'
import Button from '@material-ui/core/Button'
import { LOCAL_ROUTES } from '../../constants/routes'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import {
  fetchBrandsList,
  resetBrandsState,
  deleteBrand,
} from '../../modules/brands'
import { ROUTES, CATEGORIES_LABEL } from '@san4uru/front-utils/dist/constants'
import {
  ListItem,
  Loader,
  PageHeaderActions,
  Title,
  LoadMore,
  SearchInput,
  EmptySection,
} from '../../components'
import { constructUrl } from '@san4uru/front-utils/dist/utils'
import { navTo } from '../../utils/html'

class Brands extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      textSearch: '',
      isLoading: !props.brands.list.length,
    }
  }

  componentDidMount() {
    const { list = [] } = this.props.brands

    if (!list.length) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.brands.needForceUpdate &&
      this.props.brands.needForceUpdate
    ) {
      this.onReloadBrandsList()
    }
  }

  loadData = (params = {}) => {
    const { query, page } = params

    this.setState({ isLoading: true })
    this.props
      .fetchBrandsList({ query, page })
      .then(() => this.setState({ isLoading: false }))
  }

  onReloadBrandsList = () => {
    this.props.resetBrandsState()
    this.loadData()
  }

  onEdit = (url) => {
    navTo({ pathname: LOCAL_ROUTES.BRANDS_EDIT, params: { brand_id: url } })
  }

  onLoadNextPage = () => {
    this.loadData({
      page: this.props.brands.page + 1,
      query: this.state.textSearch || undefined,
    })
  }

  onSearch = (textSearch) => {
    this.loadData({ query: textSearch })
  }

  onRemove = (url) => {
    this.props.deleteBrand({ url: url })
  }

  render() {
    const { list = [], hasNextPage } = this.props.brands
    const { textSearch, isLoading } = this.state

    if (!list.length && !isLoading) {
      return (
        <div className="brands">
          <PageHeaderActions
            addLabel="Добавить бренд"
            onAdd={() => this.props.push(LOCAL_ROUTES.BRANDS_CREATE)}
            onReload={this.onReloadBrandsList}
          />
          <Title>Бренды</Title>
          {textSearch && (
            <SearchInput
              onSearch={this.onSearch}
              initialValue={textSearch}
              onChange={(textSearch) => this.setState({ textSearch })}
            />
          )}
          <EmptySection className="empty-screen" />
        </div>
      )
    }

    return (
      <div className="brands">
        <PageHeaderActions
          addLabel="Добавить бренд"
          onAdd={() => this.props.push(LOCAL_ROUTES.BRANDS_CREATE)}
          onReload={this.onReloadBrandsList}
        />
        <Title>Бренды</Title>
        <SearchInput
          onSearch={this.onSearch}
          initialValue={textSearch}
          onChange={(textSearch) => this.setState({ textSearch })}
        />
        <div className="brands__content">
          {list.map((item) => (
            <ListItem
              key={item.url}
              className="brands__item"
              logo={item.logo}
              content={
                <React.Fragment>
                  <span>
                    <b>Название:</b> {item.name}
                  </span>
                  <span>
                    <b>Категории:</b>{' '}
                    {item.aspects
                      .map((aspect) => CATEGORIES_LABEL[aspect])
                      .join(', ')}
                  </span>
                  {!item.active ? (
                    <React.Fragment>
                      <br />
                      <span className="text-warning">
                        <b>Не отображается на сайте</b>
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              }
              onEdit={() => this.onEdit(item.url)}
              onRemove={() => this.onRemove(item.url)}
              itemLink={constructUrl({
                pathname: `${ROUTES.BRANDS}/${item.url}`,
              })}
              additionButtons={
                <React.Fragment>
                  <Button
                    color="primary"
                    onClick={() =>
                      navTo({
                        pathname: LOCAL_ROUTES.BRAND_HISTORY,
                        params: { brand_id: item.url },
                      })
                    }>
                    Просмотр истории бренда
                  </Button>
                  <Button
                    color="primary"
                    onClick={() =>
                      navTo({
                        pathname: LOCAL_ROUTES.BRAND_COLLECTIONS,
                        params: { brand_id: item.url },
                      })
                    }>
                    Просмотр коллекций бренда
                  </Button>
                </React.Fragment>
              }
            />
          ))}
        </div>
        {isLoading && <Loader />}
        {hasNextPage && <LoadMore onClick={this.onLoadNextPage} />}
      </div>
    )
  }
}

const mapStateToProps = ({ brands }) => ({ brands })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { push, fetchBrandsList, resetBrandsState, deleteBrand },
    dispatch
  )
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Brands))
