import axios from 'axios'
import { LOCAL_ROUTES } from '../constants/routes'
import { removeCookieByName } from '../utils/html'
import { isDevEnv } from '@san4uru/front-utils/dist/utils'
import qs from 'qs'

const instance = axios.create({
  withCredentials:
    window.location.pathname.includes(LOCAL_ROUTES.OAUTH) || isDevEnv(),
  headers: {
    'Cache-Control': 'no-cache',
  },
  transformResponse: (data) => {
    let parsedData

    try {
      parsedData = JSON.parse(data)
    } catch (_) {
      if (data && data.includes('Maximum request body size')) {
        throw new Error('IMAGE_TOO_LARGE')
      }
      return data
    }

    if (!parsedData || (!parsedData.data && !parsedData.code)) {
      return parsedData
    }
    if (parsedData.code !== 200) {
      if (parsedData.code === 401) {
        removeCookieByName('AIOHTTP_SESSION')
        window.open(LOCAL_ROUTES.LOGIN, '_self')
      }

      throw parsedData.errors
    }

    return parsedData.data
  },
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: 'brackets' }),
})

export default instance
