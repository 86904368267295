import React from 'react'
import './styles.css'
import classnames from 'classnames'
import { Button } from '@material-ui/core'

const CreatorScreenFooter = ({
  className,
  onCancel,
  onSave,
  isSaveDisabled,
}) => {
  return (
    <div className={classnames('creator-screen-footer', className)}>
      <Button
        color="default"
        variant="contained"
        size="large"
        onClick={onCancel}>
        Отменить
      </Button>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={onSave}
        disabled={isSaveDisabled}>
        Сохранить
      </Button>
    </div>
  )
}
export default CreatorScreenFooter
