import { createAction, handleActions } from 'redux-actions'
import {
  fetchBrandCollection as fetchBrandCollectionRest,
  createBrandCollection,
  editBrandCollection,
  deleteBrandCollection as deleteBrandCollectionRest,
} from '../services/brands'
import { goBack } from 'connected-react-router'
import { addAlert, AlertType } from './global'

export const FETCH_BRAND_COLLECTION = createAction('FETCH_BRAND_COLLECTION')
export const SAVE_AS_DRAFT_BRAND_COLLECTION = createAction(
  'SAVE_AS_DRAFT_BRAND_COLLECTION'
)
export const UPDATE_BRAND_COLLECTION_STATE = createAction(
  'UPDATE_BRAND_COLLECTION_STATE'
)
export const RESET_BRAND_COLLECTION_STATE = createAction(
  'RESET_BRAND_COLLECTION_STATE'
)

export const createCollection = ({ brandId, ...brandCollection }) => (
  dispatch
) => {
  return createBrandCollection({
    ...brandCollection,
    brand: brandId,
  })
    .then(({ data }) => {
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'Коллекция бренда успешно создана',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const editCollection = ({ brandId, ...brandCollection }) => (
  dispatch
) => {
  return editBrandCollection({
    ...brandCollection,
    brand: brandId,
  })
    .then(({ data }) => {
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'Коллекция бренда успешно отредактирована',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const fetchBrandCollection = (id) => (dispatch) => {
  return fetchBrandCollectionRest({ id })
    .then(({ data }) => {
      dispatch(FETCH_BRAND_COLLECTION(data))

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const deleteBrandCollection = ({ id }) => (dispatch) => {
  return deleteBrandCollectionRest({ id })
    .then(() => {
      dispatch(
        addAlert({
          text: 'Коллекция бренда успешно удалена',
          type: AlertType.Success,
        })
      )

      return
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const resetBrandCollectionState = () => (dispatch) =>
  dispatch(RESET_BRAND_COLLECTION_STATE())
export const saveAsDraft = (brand) => (dispatch) =>
  dispatch(SAVE_AS_DRAFT_BRAND_COLLECTION(brand))

const baseBrandCollectionProps = {
  aspects: [],
  logo: '',
  name: '',
  description: '',
  active: true,
  url: '',
}

const initialState = {
  current: {
    ...baseBrandCollectionProps,
  },
  draft: {
    ...baseBrandCollectionProps,
  },
}

export const brandCollection = handleActions(
  {
    [FETCH_BRAND_COLLECTION]: (state, { payload }) => ({
      ...state,
      current: payload,
    }),
    [SAVE_AS_DRAFT_BRAND_COLLECTION]: (state, { payload }) => ({
      ...state,
      draft: payload,
    }),
    [UPDATE_BRAND_COLLECTION_STATE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [RESET_BRAND_COLLECTION_STATE]: () => initialState,
  },
  initialState
)
