import React from 'react'
import './styles.css'
import {
  CreatorScreenFooter,
  ErrorDescription,
  ListItem,
  Subtitle,
  Title,
  UploadAttachmentButton,
} from '../../components'
import AddIcon from '@material-ui/icons/Add'
import { Button } from '@material-ui/core'
import { push, goBack } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  editHistory,
  createHistory,
  fetchExactBrand,
  clearCurrentBrandState,
} from '../../modules/brands'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import isEqual from 'lodash-es/isEqual'
import classnames from 'classnames'
import ClearIcon from '@material-ui/icons/Clear'
import { ErrorDescriptionType } from '../../components/ErrorDescription'
import { resetGlobalState } from '../../modules/global'
import { getParamFromUrl } from '@san4uru/front-utils/dist/utils'

class BrandHistoryCreator extends React.Component {
  state = {
    name: '',
    history: [],
    url: undefined,
    brand: '',
    brand_id: null,
    id: null,
  }

  componentDidMount() {
    if (this.props.brandId) {
      this.props
        .fetchExactBrand(this.props.brandId)
        .then(({ name, url, history}) =>
          this.setState({ name, history: history.filter((el) => el.id === Number(this.props.id)), brand: url, brand_id: this.props.brand.id, id: Number(this.props.id) })
        )
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentBrandState()
    this.props.resetGlobalState()

  }

  onCancel = () => {
    this.props.goBack()
  }

  onSave = () => {
    if (!this.isCanSave()) {
      return
    }

    if (this.props.isEdit) {
      return this.props.editHistory(this.state.history, this.state.brand_id, this.state.brand)
    }
    this.props.createHistory(this.state.history,this.state.brand_id, this.state.brand )
  }

  onChangeHistoryItem = (historyFields = {}, index = 0) => {
    const { history = [] } = this.state
    
    let newHistory = [
      ...history.slice(0, index),
      { ...history[index], ...historyFields },
      ...history.slice(index + 1),
      
    ]
    
    this.setState({ history: [...newHistory] })
  }

  isCanSave = () => {
    return !isEqual(this.state, this.props.brand.history)
  }

  onAddHistoryItem = () => {
    const { history = [] } = this.state
    this.setState({
      history: [...history, { images: [], text: '', year: '', name: '' }],
    })
  }

  onRemoveHistoryItem = (index) => {
    let { history = [] } = this.state

    history.splice(index, 1)
    this.setState({ history: [...history] })
  }

  renderErrorDescription = () => {
    if (this.props.isEdit && isEqual(this.state, this.props.brand.history)) {
      return <ErrorDescription type={ErrorDescriptionType.SameContent} />
    }

    return null
  }
  

  renderHistoryItem = (
    { images = [], text = '', year = '', name = '' },
    index
  ) => {
    return (
      <div className="brand-history-creator__item">
        <ListItem
          key={year + '-' + index}
          logo={images[0] && images[0].url}
          className="brand-history-creator__main-description__item"
          content={
            <React.Fragment>
              <div
                className={classnames('brand-history-creator__main-logo', {
                  'brand-history-creator__main-logo_existed':
                    images[0] && images[0].url,
                  'brand-history-creator__main-logo_error': !(
                    images[0] && images[0].url
                  ),
                })}>
                {images[0] && images[0].url ? (
                  <React.Fragment>
                    <div
                      title="Удалить лого"
                      className="brand-history-creator__main-logo__delete"
                      onClick={() =>
                        this.onChangeHistoryItem({ images: [] }, index)
                      }>
                      <DeleteIcon />
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
              <div className="brand-history-creator__main-description">
                <TextField
                  id="year"
                  label="Год"
                  type='number'
                  value={year}
                  autoComplete="off"
                  onChange={({ target }) =>
                    this.onChangeHistoryItem({ year: Number(target.value) }, index)
                  }
                />
                <br />
                <TextField
                  id="name"
                  label="Название"
                  required
                  error={name.length === 0}
                  value={name}
                  autoComplete="off"
                  onChange={({ target }) =>
                    this.onChangeHistoryItem({ name: target.value }, index)
                  }
                />
                <br />
                <TextField
                  id="description"
                  label="Описание"
                  required
                  value={text}
                  error={text.length === 0}
                  multiline
                  rows={6}
                  autoComplete="off"
                  onChange={({ target }) =>
                    this.onChangeHistoryItem(
                      { text: target.value },
                      index
                    )
                  }
                />
                <br />
                <div className="brand-history-creator__main-logo__upload">
                  <UploadAttachmentButton
                    onFileUploadChange={(img) =>
                      this.onChangeHistoryItem({ images: [img] }, index)
                    }
                  />
                  
                </div>
              </div>
            </React.Fragment>
          }
        />
        <div
          title="Удалить историю"
          className="brand-history-creator__item__delete"
          onClick={() => this.onRemoveHistoryItem(index)}>
          <ClearIcon />
        </div>
      </div>
    )
  }

  render() {
    const { name } = this.props.brand

    return (
      <div className="brand-history-creator">
        <Title>
          {this.props.isEdit
            ? `Редактирование истории бренда ${name}`
            : 'Создание истории бренда'}
        </Title>
        {this.renderErrorDescription()}
        <div className="brand-history-creator__content">
          <Subtitle>Текущая история бренда:</Subtitle>
          {this.state.history.map(this.renderHistoryItem)}
          <br />
          <Button
            color="primary"
            variant="contained"
            className="brand-history-creator__add"
            startIcon={<AddIcon />}
            onClick={this.onAddHistoryItem}>
            Добавить пункт к истории
          </Button>
        </div>
        <CreatorScreenFooter
          onSave={this.onSave}
          onCancel={this.onCancel}
          isSaveDisabled={!this.isCanSave()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ brands }) => ({
  brandId: getParamFromUrl('brand_id'),
  brand: brands.current,
  id: getParamFromUrl('id')
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      goBack,
      editHistory,
      createHistory,
      fetchExactBrand,
      clearCurrentBrandState,
      resetGlobalState,
    },
    dispatch
  )
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandHistoryCreator)
)
