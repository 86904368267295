import { createAction, handleActions } from 'redux-actions'

export const FETCH_PROMO_LIST = createAction('FETCH_PROMO_LIST')
export const UPDATE_PROMO_LIST = createAction('UPDATE_PROMO_LIST')
export const RESET_PROMO_STATE = createAction('RESET_PROMO_STATE')

const basePromoProps = {}
const initialState = {
  list: [],
  page: 0,
  hasNextPage: true,
  current: { ...basePromoProps },
  draft: { ...basePromoProps },
}
export const promo = handleActions(
  {
    [FETCH_PROMO_LIST]: (state, { payload }) => ({
      ...state,
      list: [...payload.list],
      page: payload.page,
    }),
    [UPDATE_PROMO_LIST]: (state, { payload }) => ({
      ...state,
      list: [...state.list, ...payload.list],
      page: payload.page,
    }),
    [RESET_PROMO_STATE]: () => ({ ...initialState }),
  },
  initialState
)
