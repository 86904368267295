import transport from './transport'

export const fetchProjects = (params) =>
  transport.get('/api/admin/projects/', { params })

export const fetchExactProject = (params) =>
  transport.get('/api/admin/project/', { params })

export const createProject = ({ ...rest }) =>
  transport.post('/api/admin/projects/add/', rest)

export const editProject = ({ ...rest }) =>
  transport.post('/api/admin/projects/edit/', rest)

export const deleteProject = (rest) =>
  transport.post('/api/admin/projects/delete/', rest)
