import transport from './transport'

export const createNews = ({ ...rest }) =>
  transport.post('/api/admin/news/add/', rest)

export const editNews = ({ ...rest }) =>
  transport.post('/api/admin/news/edit/', rest)

export const fetchNewsList = (params) =>
  transport.get('/api/admin/news/', { params })

export const fetchExactNews = (params) =>
  transport.get('/api/admin/exact_news/', { params })

export const deleteNews = (rest) =>
  transport.post('/api/admin/news/delete/', rest)
