import React from 'react'
import './styles.css'
import { Typography } from '@material-ui/core'
import classnames from 'classnames'

const Subtitle = ({ children, materialUIProps = {}, className = '' }) => (
  <Typography
    variant="subtitle2"
    className={classnames('subtitle', className)}
    {...materialUIProps}>
    {children}
  </Typography>
)
export default Subtitle
