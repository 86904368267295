import React from 'react'
import './styles.css'
import { LOCAL_ROUTES } from '../../constants/routes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import {
  fetchProjectsList,
  resetProjectsState,
  deleteProject,
} from '../../modules/projects'
import { ROUTES } from '@san4uru/front-utils/dist/constants'
import {
  ListItem,
  Title,
  PageHeaderActions,
  SearchInput,
  Loader,
  LoadMore,
  EmptySection,
} from '../../components'
import { constructUrl } from '@san4uru/front-utils/dist/utils'
import { navTo } from '../../utils/html'

class Projects extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isLoading: !props.projects.list.length, textSearch: '' }
  }

  componentDidMount() {
    if (!this.props.projects.list.length) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.projects.needForceUpdate &&
      this.props.projects.needForceUpdate
    ) {
      this.onReloadProjectsList()
    }
  }

  loadData = (params = {}) => {
    const { page, query } = params

    this.setState({ isLoading: true })
    this.props
      .fetchProjectsList({ page, query })
      .then(() => this.setState({ isLoading: false }))
  }

  onReloadProjectsList = () => {
    this.props.resetProjectsState()
    this.loadData()
  }

  onLoadNextPage = () => {
    this.loadData({
      page: this.props.news.page + 1,
      query: this.state.textSearch || undefined,
    })
  }

  onSearch = (textSearch) => {
    this.loadData({ query: textSearch })
  }

  onRemove = (id) => {
    this.props.deleteProject({ id })
  }

  render() {
    const { list, hasNextPage } = this.props.projects
    const { isLoading, textSearch } = this.state

    if (!list.length && !isLoading) {
      return (
        <div className="projects">
          <PageHeaderActions
            addLabel="Добавить проект"
            onAdd={() => this.props.push(LOCAL_ROUTES.PROJECTS_CREATE)}
            onReload={this.onReloadProjectsList}
          />
          <Title>Проекты:</Title>
          {textSearch && (
            <SearchInput
              onSearch={this.onSearch}
              initialValue={textSearch}
              onChange={(textSearch) => this.setState({ textSearch })}
            />
          )}
          <EmptySection className="empty-screen" />
        </div>
      )
    }

    return (
      <div className="projects">
        <PageHeaderActions
          addLabel="Добавить проект"
          onAdd={() => this.props.push(LOCAL_ROUTES.PROJECTS_CREATE)}
          onReload={this.onReloadProjectsList}
        />
        <Title>Проекты:</Title>
        <SearchInput
          onSearch={this.onSearch}
          initialValue={textSearch}
          onChange={(textSearch) => this.setState({ textSearch })}
        />
        <div className="projects__content">
          {list.map(
            ({ id, catalog = [], name, author, description, active }) => (
              <ListItem
                key={id}
                logo={catalog[0] && catalog[0].url}
                content={
                  <React.Fragment>
                    <span>
                      <b>Название:</b> {name}
                    </span>
                    <span>
                      <b>Автор:</b> {author}
                    </span>
                    <br />
                    <span>
                      <b>Описание:</b> {description}
                    </span>
                    {!active ? (
                      <React.Fragment>
                        <br />
                        <span className="text-warning">
                          <b>Не отображается на сайте</b>
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                }
                onEdit={() =>
                  navTo({
                    pathname: LOCAL_ROUTES.PROJECTS_EDIT,
                    params: { project_id: id },
                  })
                }
                onRemove={() => this.onRemove(id)}
                itemLink={constructUrl({
                  pathname: `${ROUTES.PROJECTS}/${id}`,
                })}
              />
            )
          )}
        </div>
        {isLoading && <Loader />}
        {hasNextPage && <LoadMore onClick={this.onLoadNextPage} />}
      </div>
    )
  }
}

const mapStateToProps = ({ projects }) => ({ projects })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      fetchProjectsList,
      resetProjectsState,
      deleteProject,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Projects)
)
