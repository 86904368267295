import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { removePopup } from '../../modules/global'
import { Popup } from '../../components'

const PopupsContainer = ({ popups = [], removePopup }) => {
  return (
    <React.Fragment>
      {
        popups.map(({ created, onAccept, ...popupProps }) => (
          <Popup
            key={created}
            { ...popupProps }
            onClose={() => removePopup({ created })}
            onAccept={() => {
              onAccept && onAccept({ created })
            }}
          />
        ))
      }
    </React.Fragment>
  )
}

const mapStateToProps = ({ global: { popups } }) => ({ popups })

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removePopup
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PopupsContainer)
