import { constructUrl } from '@san4uru/front-utils/dist/utils'

export const getCookies = () => {
  let cookies = {}

  for (let cookie of document.cookie.split('; ')) {
    let [name, value] = cookie.split('=')
    cookies[name] = decodeURIComponent(value)
  }
  return cookies
}

export const getToken = () =>
  /access_token=([^&]+)/.exec(document.location.hash)[1]

export const removeCookieByName = (name) => {
  if (!name || !getCookies()[name]) return

  const cookies = document.cookie.split('; ')
  const i = cookies.indexOf(name)

  if (i === -1) return

  document.cookie = [...cookies.slice(0, i), ...cookies.slice(i + 1)].join('; ')
}

export function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

export function navTo({ params, pathname }, target) {
  const baseUrl = window.location.origin
  const url = constructUrl({ baseUrl, params, pathname })

  window.open(url, target || '_self')
}
