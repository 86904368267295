import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/App/App'
import './index.css'
import { LOCAL_ROUTES } from './constants/routes'
import { userInfo } from './modules/auth'

import { isDevEnv } from '@san4uru/front-utils/dist/utils'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

if (isDevEnv()) {
  render(
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </MuiPickersUtilsProvider>
    </Provider>,
    document.querySelector('#root')
  )
} else {
  store
    .dispatch(userInfo())
    .then((res) => {
      if (!res || !res.data) {
        throw new Error('NOT_LOGGED_IN')
      }

      return res
    })
    .catch((err) => {
      console.error(err)

      if (
        ![LOCAL_ROUTES.LOGIN, LOCAL_ROUTES.OAUTH].includes(
          window.location.pathname
        )
      ) {
        window.open(window.location.origin + LOCAL_ROUTES.LOGIN, '_self')
      }
    })
    .finally(() => {
      render(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>,
        document.querySelector('#root')
      )
    })
}
