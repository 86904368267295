import { createAction, handleActions } from 'redux-actions'
import { getRandomArbitrary } from '../utils/html'

const ADD_ALERT = createAction('ADD_ALERT')
const REMOVE_ALERT_BY_ID = createAction('REMOVE_ALERT_BY_ID')
const ADD_POPUP = createAction('ADD_POPUP')
const REMOVE_POPUP_BY_ID = createAction('REMOVE_POPUP_BY_ID')
const RESET_GLOBAL_STATE = createAction('RESET_GLOBAL_STATE')

export const addAlert = ({ type, text, title, expires = 5000 }) => (
  dispatch
) => {
  const created = getRandomArbitrary(1, 9999)
  dispatch(ADD_ALERT({ type, text, title, created }))

  const timer = setTimeout(() => {
    dispatch(REMOVE_ALERT_BY_ID({ created }))
    clearTimeout(timer)
  }, expires)

  return created
}

export const removeAlert = ({ created }) => (dispatch) =>
  dispatch(REMOVE_ALERT_BY_ID({ created }))

export const addPopup = ({
  acceptText,
  declineText,
  created,
  text,
  title,
  onAccept,
}) => (dispatch) => {
  dispatch(
    ADD_POPUP({ acceptText, declineText, text, title, created, onAccept })
  )

  return created
}

export const removePopup = ({ created }) => (dispatch) =>
  dispatch(REMOVE_POPUP_BY_ID({ created }))

export const resetGlobalState = () => (dispatch) =>
  dispatch(RESET_GLOBAL_STATE())

export class AlertType {
  static Error = 'error'
  static Success = 'success'
  static Info = 'info'
}

const initialValue = {
  alerts: [],
  popups: [],
  loading: false,
}
export const global = handleActions(
  {
    [ADD_ALERT]: (state, { payload: { title, text, type, created } }) => ({
      ...state,
      alerts: [...state.alerts, { title, text, type, created }],
    }),
    [REMOVE_ALERT_BY_ID]: (state, { payload: { created } }) => ({
      ...state,
      alerts: [...state.alerts.filter((a) => a.created !== created)],
    }),
    [ADD_POPUP]: (
      state,
      { payload: { acceptText, declineText, text, title, created, onAccept } }
    ) => ({
      ...state,
      popups: [
        ...state.popups,
        { acceptText, declineText, text, title, created, onAccept },
      ],
    }),
    [REMOVE_POPUP_BY_ID]: (state, { payload: { created } }) => ({
      ...state,
      popups: [...state.popups.filter((p) => p.created !== created)],
    }),
    [RESET_GLOBAL_STATE]: () => initialValue,
  },
  initialValue
)
