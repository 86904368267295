import {
  fetchBrandsListPath,
  fetchBrandPath,
  fetchBrandCollectionPath,
  suggestBrands,
  fetchItemsListPath,
  fetchExactItemPath,
  fetchNewsListPath,
  fetchExactNewsPath,
  project,
  projects,
} from '@san4uru/front-utils/dist/services'

export class Endpoints {
  static fetchBrandsListPath = decodeURI(fetchBrandsListPath)
  static fetchBrandPath = decodeURI(fetchBrandPath)
  static fetchBrandCollectionPath = decodeURI(fetchBrandCollectionPath)
  static suggestBrandsPath = decodeURI(suggestBrands)
  static fetchItemsListPath = decodeURI(fetchItemsListPath)
  static fetchExactItemPath = decodeURI(fetchExactItemPath)
  static fetchNewsListPath = decodeURI(fetchNewsListPath)
  static fetchExactNewsPath = decodeURI(fetchExactNewsPath)
  static project = decodeURI(project)
  static projects = decodeURI(projects)
}
