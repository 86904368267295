import React from 'react'
import './styles.css'
import classnames from 'classnames'
import { Button } from '@material-ui/core'

const LoadMore = ({ className, onClick, label }) => (
  <div className={classnames('load-more', className)}>
    <Button
      className="load-more__button"
      color="primary"
      variant="contained"
      size="large"
      onClick={onClick}>
      {label || 'Загрузить еще'}
    </Button>
  </div>
)
export default LoadMore
