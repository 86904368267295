import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { SECTIONS, SUB_SECTIONS } from '@san4uru/front-utils/dist/constants'
import './styles.css'
import { LOCAL_ROUTES } from '../../constants/routes'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

class VerticalTabs extends React.Component {
  sections = [
    ...Object.values(SUB_SECTIONS).filter(
      (s) => ![SUB_SECTIONS.SERVICES, SUB_SECTIONS.ABOUT].includes(s)
    ),
    { link: LOCAL_ROUTES.ITEMS, label: 'Товары' },
    ...Object.values(SECTIONS).filter(
      (s) => ![SECTIONS.SALES, SECTIONS.DIRECTIONS, SECTIONS.ABOUT].includes(s)
    ),
    { link: LOCAL_ROUTES.PROMO, label: 'Промо-кампании' },
  ]

  state = {
    value: ((sections) => {
      const activeSectionArr = sections.filter((s) =>
        window.location.pathname.includes(s.link)
      )
      const activeSection = activeSectionArr.length
        ? activeSectionArr[0]
        : undefined
      let activeSectionIndex = -1

      if (activeSection) {
        activeSectionIndex = sections.findIndex(
          (s) =>
            s.link === activeSection.link && s.label === activeSection.label
        )
      }
      return activeSectionIndex !== -1 ? activeSectionIndex : 0
    })(this.sections),
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })

    if (this.sections[newValue].link) {
      this.props.push(this.sections[newValue].link)
    }
  }

  render() {
    const { value } = this.state

    return (
      <div className="accordeon">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={this.handleChange}
          className="accordeon__tabs">
          {this.sections.map((section, index) => (
            <Tab
              key={section.label}
              label={section.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        {this.sections.map((section, index) => (
          <TabPanel key={section.label} value={section.label} index={index}>
            {section.label}
          </TabPanel>
        ))}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ push }, dispatch)
export default withRouter(connect(null, mapDispatchToProps)(VerticalTabs))
