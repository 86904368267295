import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import TextField from '@material-ui/core/TextField'
import { bindActionCreators } from 'redux'
import {
  saveAsDraft,
  clearCurrentItem,
  createItem,
  editItem,
  fetchExactItem,
  convertPrice,
} from '../../modules/items'
import './styles.css'
import { goBack } from 'connected-react-router'
import {
  Checkbox,
  CreatorScreenFooter,
  SelectList,
  UploadAttachmentButton,
  ErrorDescription,
  Title,
  Subtitle,
  Showcase,
  ShowcaseItem,
} from '../../components'
import debounce from 'lodash-es/debounce'
import {
  CURRENCY,
  CATEGORIES,
  CATEGORIES_LABEL,
  PRODUCT_TYPE,
  PRODUCT_LABEL_BY_TYPE,
  ITEMS_TYPES,
  ITEMS_LABEL_BY_TYPES,
} from '@san4uru/front-utils/dist/constants'
import isEqual from 'lodash-es/isEqual'
import { ErrorDescriptionType } from '../../components/ErrorDescription'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { suggestBrands } from '../../modules/brands'
import {
  getFormatedPrice,
  getParamFromUrl,
} from '@san4uru/front-utils/dist/utils'
import { resetGlobalState } from '../../modules/global'

class ItemsCreator extends React.Component {
  state = {
    brand: {},
    original_old_price: 0,
    original_price_type: 'RUB',
    category: '',
    id: undefined,
    aspect: '',
    name: '',
    alt_name: '',
    description: '',
    type: '',
    images: [],
    full_description: '',
    original_price: 0,
    article: 0,
    active: true,
    sort_arrangement: 0,

    brandSuggestOpened: false,
    mainImage: {},
    meta: {
      Title: '',
      Description: '',
      Keywords: '',
    },
  }

  componentDidMount() {
    this.onPriceChangeDebounced = debounce(this.onPriceChange, 300)

    this.props.suggestBrands()
    if (this.props.itemId && this.props.isEdit) {
      this.props
        .fetchExactItem(this.props.itemId)
        .then(({ price, original_price_type, old_price, images, ...data }) => {
          this.setState({
            original_price_type: Object.keys(CURRENCY).includes(
              original_price_type
            )
              ? original_price_type
              : undefined,
            mainImage: images[0] || {},
            images,
            ...data,
          })
        })
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentItem()
    this.props.resetGlobalState()
  }

  onCancel = () => {
    if (!this.props.isEdit) {
      this.props.saveAsDraft(this.props)
    }
    this.props.goBack()
  }

  onSave = () => {
    if (!this.isCanSave) {
      return
    }

    const {
      brandSuggestOpened,
      mainImage,
      images: stateImages,
      ...state
    } = this.state

    let images = stateImages.slice()
    const i = stateImages.findIndex(({ url }) => url === mainImage.url)

    if (i !== -1) {
      images = [
        mainImage,
        ...stateImages.slice(0, i),
        ...stateImages.slice(i + 1),
      ]
    }

    if (this.props.isEdit) {
      return this.props.editItem({ images, ...state })
    }
    this.props.createItem({ images, ...state })
  }

  onPriceChange = (price) => {
    this.setState({ convertingPrice: true })

    this.props
      .convertPrice({
        price,
        currency: this.state.original_price_type,
      })
      .then(() => this.setState({ convertingPrice: false }))
  }

  onBrandChange = ({ target }) => {
    const brand = this.props.brands.find((b) => b.name === target.textContent)
    if (brand) {
      this.setState({ brand })
    }
  }

  onBrandInputChange = (e) => {
    if (e && e.target) {
      this.props.suggestBrands(e.target.value)
    }
  }

  isCanSave = () => {
    const { brandSuggestOpened, mainImage, ...state } = this.state
    const { name, article, category, type, aspect, brand } = state

    return (
      name.length &&
      category &&
      type &&
      aspect &&
      article.length &&
      brand &&
      brand.url &&
      (this.props.isEdit ? !isEqual(state, this.props.currentItem) : true)
    )
  }

  onRemoveImage = (imageUrl) => {
    const { images = [] } = this.state
    const index = images.findIndex(({ url }) => url === imageUrl)

    if (index === -1) return

    this.setState({
      images: [...images.slice(0, index), ...images.slice(index + 1)],
    })
  }

  onSetMainImage = (mainImage) => {
    this.setState({ mainImage })
  }

  onFileUploadChange = (image) => {
    const { images } = this.state

    if (!images.length) {
      this.onSetMainImage(image)
    }
    this.setState({ images: [...images, image] })
  }

  renderErrorDescription = () => {
    const { brandSuggestOpened, mainImage, ...state } = this.state
    if (this.props.isEdit && isEqual(state, this.props.currentItem)) {
      return <ErrorDescription type={ErrorDescriptionType.SameContent} />
    }

    if (!this.isCanSave()) {
      const { name, aspect, article, type, category, brand } = this.state
      const fields = [
        !name.length ? 'name' : undefined,
        !aspect ? 'aspects' : undefined,
        !article ? 'article' : undefined,
        !type ? 'type' : undefined,
        !category ? 'category' : undefined,
        !brand || !brand.url ? 'brand' : undefined,
      ]
      return (
        <ErrorDescription
          type={ErrorDescriptionType.EmptyFields}
          fields={fields.filter(Boolean)}
        />
      )
    }

    return null
  }
  formFieldChangeHandler = ({ target }) => {
    this.setState({meta: {
      ...this.state.meta,
      [target.name] : target.value,
    }})
  }

  render() {
    const { isEdit = false, currentItem, brands, isBrandsLoading } = this.props
    const {
      name,
      alt_name,
      description,
      full_description,
      original_price,
      original_old_price,
      original_price_type,
      sort_arrangement,
      article,
      images = [],
      aspect,
      category,
      type,
      brand,
      brandSuggestOpened,
      meta = {},
    } = this.state

    return (
      <div className="items-creator">
        <Title>{isEdit ? 'Редактирование товара' : 'Создание товара'}</Title>
        {this.renderErrorDescription()}
        <div className="items-creator__name">
          <TextField
            id="name"
            label="Название"
            required
            error={!name.length}
            value={name}
            autoComplete="off"
            autoFocus
            onChange={({ target }) => this.setState({ name: target.value })}
          />
          <Checkbox
            label="Сразу отображать активным на сайте"
            checked={!!this.state.active}
            onChange={({ target }) => this.setState({ active: target.checked })}
          />
        </div>
        <br />
        <div className="items-creator__name">
          <TextField
            id="alt_name"
            label="Альтернативное название"
            value={alt_name}
            autoComplete="off"
            autoFocus
            onChange={({ target }) => this.setState({ alt_name: target.value })}
          />
          <Checkbox
            label="Сразу отображать активным на сайте"
            checked={!!this.state.active}
            onChange={({ target }) => this.setState({ active: target.checked })}
          />
        </div>
        <br />
        <div className="items-creator__prices">
          <TextField
            id="price"
            label="Актуальная цена"
            type="num"
            required
            value={original_price}
            autoComplete="off"
            onChange={({ target }) => {
              this.setState({ original_price: target.value })
              this.onPriceChangeDebounced(target.value)
            }}
          />
          <TextField
            id="old_price"
            label="Старая цена"
            type="num"
            value={original_old_price}
            autoComplete="off"
            onChange={({ target }) =>
              this.setState({ original_old_price: target.value })
            }
          />
          <SelectList
            className="items-creator__currency-select"
            label="Валюта"
            value={original_price_type}
            options={Object.keys(CURRENCY)}
            optionLabels={Object.keys(CURRENCY)}
            onChange={({ target }) =>
              this.setState({ original_price_type: target.value })
            }
          />
          <TextField
            id="converted_price"
            label="Цена на сайте"
            disabled
            type="num"
            value={currentItem.price ? getFormatedPrice(currentItem.price) : ''}
            autoComplete="off"
          />
        </div>
        <br />
        <div className="items-creator__availability-info">
          <TextField
            id="article"
            label="Артикул"
            type="num"
            error={!article.length}
            required
            value={article}
            autoComplete="off"
            onChange={({ target }) => this.setState({ article: target.value })}
          />
          <Autocomplete
            id="brand-suggest"
            className="items-creator__brand-suggest"
            open={brandSuggestOpened}
            onOpen={() => this.setState({ brandSuggestOpened: true })}
            onClose={() => this.setState({ brandSuggestOpened: false })}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            value={brand}
            options={brands}
            loading={isBrandsLoading}
            onChange={this.onBrandChange}
            onInputChange={this.onBrandInputChange}
            noOptionsText="Пока пусто..."
            renderInput={(params) => (
              <TextField
                {...params}
                error={!brand || !brand.url}
                label="Бренд"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isBrandsLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
        <br />
        <div className="items-creator__special-fields">
          <Subtitle>Специальные поля:</Subtitle>
          <TextField
            id="sort_arrangement"
            label="Числовая сортировка"
            variant="outlined"
            type="number"
            className="items-creator__special-fields__sort-arr"
            value={sort_arrangement}
            autoComplete="off"
            inputProps={{ min: '0', step: '10' }}
            error={sort_arrangement % 10}
            helperText={
              sort_arrangement % 10
                ? 'Шаг между значениями должен быть равен 10'
                : ''
            }
            onChange={({ target }) =>
              this.setState({ sort_arrangement: target.value || 0 })
            }
          />
        </div>
        <br />
        <TextField
          id="description"
          label="Краткое описание"
          required
          value={description}
          multiline
          rows={4}
          autoComplete="off"
          onChange={({ target }) =>
            this.setState({ description: target.value })
          }
        />
        <br />
        <TextField
          id="full_description"
          label="Полное описание"
          required
          value={full_description}
          multiline
          rows={6}
          autoComplete="off"
          onChange={({ target }) =>
            this.setState({ full_description: target.value })
          }
        />
        <br />
        <SelectList
          label="Категория"
          error={!aspect}
          onChange={({ target }) => this.setState({ aspect: target.value })}
          value={aspect}
          options={Object.values(CATEGORIES).filter(Boolean)}
          optionLabels={CATEGORIES_LABEL}
        />
        <br />
        <SelectList
          value={type}
          onChange={({ target }) => this.setState({ type: target.value })}
          options={Object.values(PRODUCT_TYPE).filter(Boolean)}
          optionLabels={PRODUCT_LABEL_BY_TYPE}
          label="Тип товара"
          error={!type}
        />
        <br />
        <SelectList
          label="Категория товара"
          value={category}
          error={!category}
          options={Object.values(ITEMS_TYPES).filter(Boolean)}
          optionLabels={ITEMS_LABEL_BY_TYPES}
          onChange={({ target }) => this.setState({ category: target.value })}
        />
        <br />
        <h2>Заполните мета-данные</h2>
          <TextField
            id="Title"
            label="title"
            value={meta.Title}
            name='Title'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Description"
            label="description"
            value={meta.Description}
            name='Description'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Keywords"
            label="keywords"
            value={meta.Keywords}
            name='Keywords'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
        <Subtitle>Изображения</Subtitle>
        <div className="items-creator__images-container">
          <UploadAttachmentButton
            multiple
            onFileUploadChange={this.onFileUploadChange}
          />
          <div className="items-creator__images">
            <Showcase
              onRemoveAll={() => this.setState({ images: [] })}
              gallery={images}
              onRemove={this.onImageRemove}
              customItemRender={(image) => (
                <ShowcaseItem
                  key={image.url}
                  image={image}
                  onRemove={() => this.onRemoveImage(image.url)}
                  onSetMainImage={() => this.onSetMainImage(image)}
                  isMainImage={
                    image.url && image.url === this.state.mainImage.url
                  }
                />
              )}
            />
          </div>
        </div>
        <br />
        <CreatorScreenFooter
          onCancel={this.onCancel}
          onSave={this.onSave}
          isSaveDisabled={!this.isCanSave()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ items, brands }) => {
  return {
    brands: brands.suggest.list,
    isBrandsLoading: brands.suggest.isLoading,
    currentItem: items.current,
    itemId: getParamFromUrl('item_id'),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goBack,
      saveAsDraft,
      fetchExactItem,
      createItem,
      clearCurrentItem,
      editItem,
      convertPrice,
      suggestBrands,
      resetGlobalState,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ItemsCreator)
)
