import React from 'react'
import './styles.css'
import {
  ListItem,
  Loader,
  LoadMore,
  PageHeaderActions,
  SearchInput,
  Title,
  EmptySection,
} from '../../components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { LOCAL_ROUTES } from '../../constants/routes'
import { navTo } from '../../utils/html'

class Promo extends React.Component {
  state = { textSearch: '', isLoading: false }

  onLoad = () => {}

  onReload = () => {}

  onRemove = (id) => {}

  onTextSearchChange = ({ target }) => {
    this.setState({ textSearch: target.value })
  }

  render() {
    const { list, hasNextPage } = this.props.promo
    const { isLoading } = this.state

    if (!isLoading && !list.length) {
      return (
        <div className="promo">
          <PageHeaderActions
            onAdd={() => this.props.push(LOCAL_ROUTES.PROMO_CREATE)}
            onReload={this.onReload}
            addLabel="Добавить промо-кампанию"
          />
          <Title>Промо-кампании</Title>
          <SearchInput onSearch={this.onTextSearchChange} />
          <EmptySection className="empty-screen" />
        </div>
      )
    }

    return (
      <div className="promo">
        <PageHeaderActions
          onAdd={() => this.props.push(LOCAL_ROUTES.PROMO_CREATE)}
          onReload={this.onReload}
          addLabel="Добавить промо-кампанию"
        />
        <Title>Промо-кампании</Title>
        <SearchInput onSearch={this.onTextSearchChange} />
        <div className="promo__content">
          {list.map((item) => (
            <ListItem
              key={item.id}
              logo={undefined}
              content={
                <React.Fragment>
                  <b>Название:</b>
                  <b>Компания:</b>
                  <b>Сроки:</b>
                  <b>Текст:</b>
                </React.Fragment>
              }
              onEdit={() =>
                navTo({
                  pathname: LOCAL_ROUTES.PROMO_EDIT,
                  params: { promo_id: item.id },
                })
              }
              onRemove={() => this.onRemove(item.id)}
            />
          ))}
        </div>
        {isLoading && <Loader />}
        {hasNextPage && <LoadMore onClick={this.onLoadNextPage} />}
      </div>
    )
  }
}
const mapStateToProps = ({ promo }) => ({ promo })
const mapDispatchToProps = (dispatch) => bindActionCreators({ push }, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Promo))
