import React from 'react'
import './styles.css'
import { LOCAL_ROUTES } from '../../constants/routes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import {
  fetchItemsList,
  resetItemsState,
  deleteItem,
} from '../../modules/items'
import {
  PRODUCT_LABEL_BY_TYPE,
  ITEMS_LABEL_BY_TYPES,
} from '@san4uru/front-utils/dist/constants'
import { getFormatedPrice } from '@san4uru/front-utils/dist/utils'
import {
  ListItem,
  Loader,
  LoadMore,
  PageHeaderActions,
  SearchInput,
  Title,
  EmptySection,
} from '../../components'
import { constructUrl } from '@san4uru/front-utils/dist/utils'
import { navTo } from '../../utils/html'

class Items extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isLoading: !props.items.list.length, textSearch: '' }
  }

  componentDidMount() {
    if (!this.props.items.list.length) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.items.needForceUpdate && this.props.items.needForceUpdate) {
      this.onReloadItemsList()
    }
  }

  loadData = (params = {}) => {
    const { page, query } = params

    this.setState({ isLoading: true })
    this.props
      .fetchItemsList({ page, query })
      .then(() => this.setState({ isLoading: false }))
  }

  onReloadItemsList = () => {
    this.props.resetItemsState()
    this.loadData()
  }

  onLoadNextPage = () => {
    this.loadData({
      page: this.props.items.page + 1,
      query: this.state.textSearch || undefined,
    })
  }

  onSearch = (query) => {
    this.loadData({ query })
  }

  onRemove = (id) => {
    this.props.deleteItem({ id })
  }

  render() {
    const { list, hasNextPage } = this.props.items
    const { isLoading, textSearch } = this.state

    if (!list.length && !isLoading) {
      return (
        <div className="items">
          <PageHeaderActions
            addLabel="Добавить товар"
            onAdd={() => this.props.push(LOCAL_ROUTES.ITEMS_CREATE)}
            onReload={this.onReloadItemsList}
          />
          <Title>Товары:</Title>
          {textSearch && (
            <SearchInput
              onSearch={this.onSearch}
              initialValue={textSearch}
              onChange={(textSearch) => this.setState({ textSearch })}
            />
          )}
          <EmptySection className="empty-screen" />
        </div>
      )
    }

    return (
      <div className="items">
        <PageHeaderActions
          addLabel="Добавить товар"
          onAdd={() => this.props.push(LOCAL_ROUTES.ITEMS_CREATE)}
          onReload={this.onReloadItemsList}
        />
        <Title>Товары:</Title>
        <SearchInput
          onSearch={this.onSearch}
          initialValue={textSearch}
          onChange={(textSearch) => this.setState({ textSearch })}
        />
        <div className="items__content">
          {list.map(
            ({
              id,
              images = [],
              name,
              alt_name,
              brand,
              description,
              type,
              category,
              article,
              price,
              old_price,
              original_price,
              original_price_type,
              original_old_price,
              active,
            }) => (
              <ListItem
                key={id}
                logo={images[0] && images[0].url}
                content={
                  <React.Fragment>
                    <span>
                      <b>ID</b>: {id}
                    </span>
                    <span>
                      <b>Название</b>: {name}
                    </span>
                    <span>
                      <b>Альтернативное название</b>: {alt_name}
                    </span>
                    <span>
                      <b>Бренд</b>: {brand.name}
                    </span>
                    <span>
                      <b>Описание</b>: {description}
                    </span>
                    <span>
                      <b>Тип товара</b>: {PRODUCT_LABEL_BY_TYPE[type]}
                    </span>
                    <span>
                      <b>Категория</b>: {ITEMS_LABEL_BY_TYPES[category]}
                    </span>
                    <span>
                      <b>Артикул</b>: {article}
                    </span>
                    <br />
                    <span>
                      <b>Цена на сайте</b>:{' '}
                      {price ? getFormatedPrice(price) : ' - '}
                    </span>
                    <span>
                      <b>Старая цена на сайте</b>:{' '}
                      {old_price ? getFormatedPrice(old_price) : ' - '}
                    </span>
                    <br />
                    <span>
                      <b>Оригинальная цена</b>:{' '}
                      {original_price
                        ? original_price.toLocaleString('en-EN', {
                            style: 'currency',
                            currency: original_price_type,
                          })
                        : ' - '}
                    </span>
                    <span>
                      <b>Оригинальная старая цена</b>:{' '}
                      {original_old_price
                        ? original_old_price.toLocaleString('en-EN', {
                            style: 'currency',
                            currency: original_price_type,
                          })
                        : ' - '}
                    </span>
                    {!active ? (
                      <React.Fragment>
                        <br />
                        <span className="text-warning">
                          <b>Не отображается на сайте</b>
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                }
                itemLink={constructUrl({
                  pathname: `${LOCAL_ROUTES.ITEM}/${id}`,
                })}
                onEdit={() =>
                  navTo({
                    pathname: LOCAL_ROUTES.ITEMS_EDIT,
                    params: { item_id: id },
                  })
                }
                onRemove={() => this.onRemove(id)}
              />
            )
          )}
        </div>
        {isLoading && <Loader />}
        {hasNextPage && <LoadMore onClick={this.onLoadNextPage} />}
      </div>
    )
  }
}

const mapStateToProps = ({ items }) => ({ items })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      resetItemsState,
      fetchItemsList,
      deleteItem,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Items))
