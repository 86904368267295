import transport from './transport'
import { Endpoints } from './endpoints'

export const fetchBrandsList = (rest) =>
  transport.post('/api/admin/brands/', rest)

export const fetchExactBrand = (rest) =>
  transport.post('/api/admin/brand/', rest)

export const fetchBrandCollection = (params) =>
  transport.get('/api/admin/brand_collection/', { params })

export const suggestBrands = (rest) =>
  transport.post(Endpoints.suggestBrandsPath, rest)

export const createBrand = ({ ...rest }) =>
  transport.post('/api/admin/brands/add/', rest)

export const editBrand = ({ ...rest }) =>
  transport.post('/api/admin/brands/edit/', rest)

export const createBrandCollection = ({ ...rest }) =>
  transport.post('/api/admin/brand_collection/add/', rest)

export const editBrandCollection = ({ ...rest }) =>
  transport.post('/api/admin/brand_collection/edit/', rest)

export const createBrandHistory = ({ ...rest }) =>
  transport.post('/api/admin/brand_history/add/', rest)

export const editBrandHistory = ({ ...rest }) =>
  transport.post('/api/admin/brand_history/edit/', rest)

export const deleteBrand = (rest) =>
  transport.post('/api/admin/brands/delete/', rest)

export const deleteBrandCollection = (rest) =>
  transport.post('/api/admin/brand_collection/delete/', rest)

export const deleteBrandHistory = (rest) =>
  transport.post('/api/admin/brand_history/delete/', rest)
