import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import TextField from '@material-ui/core/TextField'
import { bindActionCreators } from 'redux'
import {
  saveAsDraft,
  fetchExactProject,
  createProject,
  editProject,
  clearCurrentProject,
} from '../../modules/projects'
import './styles.css'
import { goBack } from 'connected-react-router'
import {
  CreatorScreenFooter,
  Title,
  Subtitle,
  UploadAttachmentButton,
  Checkbox,
  ErrorDescription,
  Showcase,
  ShowcaseItem,
} from '../../components'
import isEqual from 'lodash-es/isEqual'
import { ErrorDescriptionType } from '../../components/ErrorDescription'
import { resetGlobalState } from '../../modules/global'
import { getParamFromUrl } from '@san4uru/front-utils/dist/utils'

class ProjectCreator extends React.Component {
  state = {
    id: undefined,
    name: '',
    catalog: [],
    description: '',
    author: '',
    active: true,

    mainImage: {},
    meta: {
      Title: '',
      Description: '',
      Keywords: '',
    },
  }

  componentDidMount() {
    if (this.props.projectId) {
      this.props
        .fetchExactProject(this.props.projectId)
        .then(({ text: description, catalog, ...project }) => {
          this.setState({
            description,
            catalog,
            mainImage: catalog[0] || {},
            ...project,
          })
        })
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentProject()
    this.props.resetGlobalState()
  }

  onCancel = () => {
    const { name, description, catalog, author, active } = this.state

    if (!this.props.isEdit) {
      this.props.saveAsDraft({ name, description, catalog, author, active })
    }
    this.props.goBack()
  }

  onSave = () => {
    if (!this.isCanSave()) {
      return
    }

    const { mainImage, catalog: stateImages, ...state } = this.state
    let catalog = stateImages.slice()
    const i = stateImages.findIndex(({ url }) => url === mainImage.url)

    if (i !== -1) {
      catalog = [
        mainImage,
        ...stateImages.slice(0, i),
        ...stateImages.slice(i + 1),
      ]
    }

    this.props.isEdit
      ? this.props.editProject({ catalog, ...state })
      : this.props.createProject({ catalog, ...state })
  }

  isCanSave = () => {
    const { mainImage, ...state } = this.state
    const { name, author } = state

    return (
      name.length &&
      author.length &&
      (this.props.isEdit ? !isEqual(state, this.props.currentProject) : true)
    )
  }

  onRemoveImage = (imageUrl) => {
    const { catalog: images = [], mainImage } = this.state
    const index = images.findIndex(({ url }) => url === imageUrl)

    if (index === -1) return

    if (mainImage.url === images[index].url) {
      this.setState({ mainImage: index === 0 ? images[1] : images[0] })
    }

    this.setState({
      catalog: [...images.slice(0, index), ...images.slice(index + 1)],
    })
  }

  onSetMainImage = (mainImage) => {
    this.setState({ mainImage })
  }

  onFileUploadChange = (image) => {
    const { catalog: images = [] } = this.state

    if (!images.length) {
      this.onSetMainImage(image)
    }
    this.setState({ catalog: [...images, image] })
  }

  renderErrorDescription = () => {
    if (this.props.isEdit && isEqual(this.state, this.props.currentProject)) {
      return <ErrorDescription type={ErrorDescriptionType.SameContent} />
    }

    if (!this.isCanSave()) {
      const { name, author } = this.state
      const fields = [
        !name.length ? 'name' : undefined,
        !author.length ? 'author' : undefined,
      ]
      return (
        <ErrorDescription
          type={ErrorDescriptionType.EmptyFields}
          fields={fields.filter(Boolean)}
        />
      )
    }

    return null
  }
  formFieldChangeHandler = ({ target }) => {
    this.setState({meta: {
      ...this.state.meta,
      [target.name] : target.value,
    }})
  }

  render() {
    const { isEdit = false } = this.props
    const { name, description, author, active, catalog, meta = {} } = this.state

    return (
      <div className="projects-creator">
        <Title>
          {isEdit ? `Редактирование проекта "${name}"` : 'Создание проекта'}
        </Title>
        {this.renderErrorDescription()}
        <div className="projects-creator__main">
          <TextField
            id="name"
            className="projects-creator__name"
            label="Название"
            required
            error={!name.length}
            value={name}
            autoComplete="off"
            autoFocus
            onChange={({ target }) => this.setState({ name: target.value })}
          />
          <Checkbox
            label="Сразу отображать активным на сайте"
            checked={!!active}
            onChange={({ target }) => this.setState({ active: target.checked })}
          />
        </div>
        <br />
        <TextField
          id="author"
          label="Автор"
          error={!author.length}
          required
          value={author}
          autoComplete="off"
          autoFocus
          onChange={({ target }) => this.setState({ author: target.value })}
        />
        <br />
        <TextField
          id="description"
          label="Описание"
          required
          value={description}
          multiline
          rows={6}
          autoComplete="off"
          onChange={({ target }) =>
            this.setState({ description: target.value })
          }
        />
        <br />
        <h2>Заполните мета-данные</h2>
          <TextField
            id="Title"
            label="title"
            value={meta.Title}
            name='Title'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Description"
            label="description"
            value={meta.Description}
            name='Description'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
          <TextField
            id="Keywords"
            label="keywords"
            value={meta.Keywords}
            name='Keywords'
            autoComplete="off"
            onChange={this.formFieldChangeHandler}
          />
          <br />
        <Subtitle>Изображения</Subtitle>
        <div className="projects-creator__images-container">
          <UploadAttachmentButton
            multiple
            onFileUploadChange={this.onFileUploadChange}
          />
          <div className="projects-creator__images">
            <Showcase
              onRemoveAll={() => this.setState({ catalog: [] })}
              gallery={catalog}
              customItemRender={(image) => (
                <ShowcaseItem
                  key={image.url}
                  image={image}
                  onRemove={() => this.onRemoveImage(image.url)}
                  onSetMainImage={() => this.onSetMainImage(image)}
                  isMainImage={
                    image.url && image.url === this.state.mainImage.url
                  }
                />
              )}
            />
          </div>
        </div>
        <br />
        <CreatorScreenFooter
          onCancel={this.onCancel}
          onSave={this.onSave}
          isSaveDisabled={!this.isCanSave()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ projects }) => {
  return {
    currentProject: projects.current,
    projectId: getParamFromUrl('project_id'),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goBack,
      saveAsDraft,
      fetchExactProject,
      createProject,
      editProject,
      clearCurrentProject,
      resetGlobalState,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectCreator)
)
