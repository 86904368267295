import { createAction, handleActions } from 'redux-actions'
import {
  fetchBrandsList as fetchBrandsListRest,
  fetchExactBrand as fetchExactBrandRest,
  createBrand as createBrandRest,
  editBrand as editBrandRest,
  createBrandHistory,
  editBrandHistory,
  suggestBrands as suggestBrandsRests,
  deleteBrand as deleteBrandRest,
  deleteBrandHistory as deleteBrandHistoryRest,
} from '../services/brands'
import { goBack } from 'connected-react-router'
import { addAlert, AlertType } from './global'

export const FETCH_BRANDS_LIST = createAction('FETCH_BRANDS_LIST')
export const FETCH_EXACT_BRAND = createAction('FETCH_EXACT_BRAND')
export const SAVE_AS_DRAFT_BRAND = createAction('SAVE_AS_DRAFT_BRAND')
export const UPDATE_BRANDS_STATE = createAction('UPDATE_BRANDS_STATE')
export const UPDATE_BRANDS_SUGGEST_STATE = createAction(
  'UPDATE_BRANDS_SUGGEST_STATE'
)
export const UPDATE_EXACT_BRAND_STATE = createAction('UPDATE_EXACT_BRAND_STATE')
export const RESET_BRANDS_STATE = createAction('RESET_BRANDS_STATE')

export const fetchBrandsList = (params = {}) => (dispatch, getState) => {
  const { page = 0, query } = params

  return fetchBrandsListRest({ page, query })
    .then(({ data }) => {
      if (page === 0) {
        dispatch(FETCH_BRANDS_LIST(data.brands))
        dispatch(
          UPDATE_BRANDS_STATE({
            page,
            hasNextPage:
              !getState().brands.list.length ||
              getState().brands.list.length < data.total,
            needForceUpdate: false,
          })
        )

        return { page, list: data.brands }
      }

      dispatch(FETCH_BRANDS_LIST([...getState().brands.list, ...data.brands]))
      dispatch(
        UPDATE_BRANDS_STATE({
          page,
          hasNextPage: getState().brands.list.length < data.total,
        })
      )

      return { page, list: data.brands }
    })
    .catch((err) => console.error(err))
}

export const fetchExactBrand = (name) => (dispatch) => {
  return fetchExactBrandRest({ name })
    .then(({ data }) => {

      dispatch(FETCH_EXACT_BRAND(data))
      return data
    })
    .catch((err) => console.error(err))
}

export const createBrand = ({ aspects, logo, name, description, active, meta }) => (
  dispatch
) => {
  dispatch(SAVE_AS_DRAFT_BRAND({ aspects, logo, name, description, active, meta }))

  return createBrandRest({ aspects, logo, name, description, active, meta })

    .then(({ data }) => {
      dispatch(
        UPDATE_BRANDS_STATE({ draft: baseBrandProps, needForceUpdate: true })
      )
      dispatch(goBack())

      dispatch(
        addAlert({ text: 'Бренд успешно создан', type: AlertType.Success })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const editBrand = ({
  aspects,
  logo,
  name,
  description,
  active,
  url,
  meta,

}) => (dispatch) => {
  return editBrandRest({ aspects, logo, name, description, active, url, meta })
    .then(({ data }) => {
      dispatch(UPDATE_BRANDS_STATE({ needForceUpdate: true }))
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'Бренд успешно отредактирован',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const createHistory = (brandHistory, brandId, brandName) => (dispatch) => {
  const obj = brandHistory[0]
  obj.brand_id = brandId
  obj.brand = brandName
  return createBrandHistory(obj)
    .then(({ data }) => {
      dispatch(UPDATE_EXACT_BRAND_STATE(data))
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'История бренда успешно создана',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const editHistory = (brandHistory, brandId, brandName) => (dispatch) => {
  const obj = brandHistory[0]
  obj.brand_id = brandId
  obj.brand = brandName
  return editBrandHistory(obj)
    .then(({ data }) => {
      dispatch(UPDATE_EXACT_BRAND_STATE(data))
      dispatch(goBack())

      dispatch(
        addAlert({
          text: 'История бренда успешно отредактирована',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const deleteBrandHistory = ({ id }) => (dispatch) => {
  return deleteBrandHistoryRest({ id })
    .then(({ data }) => {
      dispatch(UPDATE_BRANDS_STATE({ needForceUpdate: true }))
      dispatch(
        addAlert({
          text: 'История бренда успешно удалена',
          type: AlertType.Success,
        })
      )

      return data
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const deleteBrand = ({ url }) => (dispatch) => {
  return deleteBrandRest({ url })
    .then(() => {
      dispatch(UPDATE_BRANDS_STATE({ needForceUpdate: true }))
      dispatch(
        addAlert({
          text: 'Бренд успешно удален',
          type: AlertType.Success,
        })
      )

      return
    })
    .catch((err) => {
      dispatch(
        addAlert({
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз или обратитесь к разработчикам',
          type: AlertType.Error,
        })
      )
      console.error(err)
    })
}

export const suggestBrands = (query = '') => (dispatch) => {
  dispatch(UPDATE_BRANDS_SUGGEST_STATE({ loading: true }))

  return suggestBrandsRests({ query })
    .then(({ data }) => {
      dispatch(UPDATE_BRANDS_SUGGEST_STATE({ list: data, loading: false }))
      return data
    })
    .catch((err) => {
      console.error(err)
      dispatch(UPDATE_BRANDS_SUGGEST_STATE({ loading: false }))
    })
}

export const resetBrandsState = () => (dispatch) =>
  dispatch(RESET_BRANDS_STATE())
export const saveAsDraft = (brand) => (dispatch) =>
  dispatch(SAVE_AS_DRAFT_BRAND(brand))
export const clearCurrentBrandState = () => (dispatch) =>
  dispatch(UPDATE_BRANDS_STATE({ current: baseBrandProps }))

const baseBrandProps = {
  aspects: [],
  logo: '',
  name: '',
  description: '',
  active: true,
  url: '',
}

const initialState = {
  list: [],
  page: 0,
  hasNextPage: true,
  needForceUpdate: false,
  current: {
    ...baseBrandProps,
  },
  draft: {
    ...baseBrandProps,
  },
  suggest: {
    list: [],
    loading: false,
  },
}

export const brands = handleActions(
  {
    [FETCH_BRANDS_LIST]: (state, { payload }) => ({
      ...state,
      list: [...payload],
    }),
    [FETCH_EXACT_BRAND]: (state, { payload }) => ({
      ...state,
      current: payload,
    }),
    [SAVE_AS_DRAFT_BRAND]: (state, { payload }) => ({
      ...state,
      draft: payload,
    }),
    [UPDATE_EXACT_BRAND_STATE]: (state, { payload }) => {
      let { list } = state
      const i = list.findIndex((b) => b.url === payload.url)
      if (i === -1) {
        return state
      }

      list[i] = { ...list[i], ...payload }
      return {
        ...state,
        list: [...list],
      }
    },
    [UPDATE_BRANDS_SUGGEST_STATE]: (state, { payload }) => ({
      ...state,
      suggest: {
        ...state.suggest,
        ...payload,
      },
    }),
    [UPDATE_BRANDS_STATE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [RESET_BRANDS_STATE]: () => initialState,
  },
  initialState
)
