import React from 'react'
import './styles.css'
import { Alert, AlertTitle } from '@material-ui/lab'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addAlert, AlertType, removeAlert } from '../../modules/global'

class AlertsContainer extends React.Component {
  onClose = (created) => {
    this.props.removeAlert({ created })
  }

  render() {
    return (
      <div className="alerts">
        {this.props.alerts.map(({ title, type, text, created }) => (
          <Alert
            key={`${created}-${encodeURI(title)}`}
            severity={type || AlertType.Info}
            onClose={() => this.onClose(created)}>
            {title ? <AlertTitle>{title}</AlertTitle> : null}
            {text}
          </Alert>
        ))}
      </div>
    )
  }
}
const mapStateToProps = ({ global: { alerts } }) => ({ alerts })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addAlert,
      removeAlert,
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer)
