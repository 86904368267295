import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  ListItem,
  Loader,
  PageHeaderActions,
  Subtitle,
  Title,
  EmptySection,
} from '../../components'
import { fetchExactBrand, clearCurrentBrandState } from '../../modules/brands'
import { LOCAL_ROUTES } from '../../constants/routes'
import { getParamFromUrl } from '@san4uru/front-utils/dist/utils'
import { navTo } from '../../utils/html'

class BrandHistory extends React.Component {
  state = { isLoading: true }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.props.clearCurrentBrandState()
  }

  loadData = () => {
    this.setState({ isLoading: true })
    this.props
      .fetchExactBrand(this.props.brandId)
      .then(({ history }) => this.setState({ isLoading: false, history }))
  }

  onReload = () => {
    this.props.clearCurrentBrandState()
    this.loadData()
  }

  onRemove = (url, index) => {}

  render() {
    const { collections = [], url, name, history = [] } = this.props.brand
    const { isLoading } = this.state

    if (!collections.length && !isLoading) {
      return (
        <div className="brand-history">
          <PageHeaderActions
            onAdd={() =>
              navTo({
                params: { brand_id: url },
                pathname: LOCAL_ROUTES.BRANDS_CREATE_HISTORY,
              })
            }
            onReload={this.onReload}
          />
          <Title>История бренда {name}</Title>
          <EmptySection className="empty-screen" />
        </div>
      )
    }

    return (
      <div className="brand-history">
        <PageHeaderActions
          onAdd={() =>
            navTo({
              pathname: LOCAL_ROUTES.BRANDS_CREATE_HISTORY,
              params: { brand_id: url },
            })
          }
          onReload={this.onReload}
        />
        <Title>История бренда {name}</Title>
        <div className="brand-history__content">
          {history.map(({ images, text, year, name }, index) => (
            <ListItem
              onEdit={() =>
                navTo({
                  pathname: LOCAL_ROUTES.BRANDS_EDIT_HISTORY,
                  params: { brand_id: url, id: history[index].id },
                })
              }
              onRemove={() => this.onRemove(url, index)}
              logo={images[0].url}
              content={
                <div className="brand-history-creator__item-description">
                  <Subtitle>
                    {year} - {name}
                  </Subtitle>
                  <span>{text}</span>
                </div>
              }
            />
          ))}
        </div>
        {isLoading && <Loader />}
      </div>
    )
  }
}

const mapStateToProps = ({ brands }) => ({
  brand: brands.current,
  brandId: getParamFromUrl('brand_id'),
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      fetchExactBrand,
      clearCurrentBrandState,
    },
    dispatch
  )
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandHistory)
)
