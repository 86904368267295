import React from 'react'
import './styles.css'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  ListItem,
  Loader,
  PageHeaderActions,
  SearchInput,
  Title,
  EmptySection,
} from '../../components'
import { fetchExactBrand, clearCurrentBrandState } from '../../modules/brands'
import { deleteBrandCollection } from '../../modules/brand_collection'
import { ROUTES } from '@san4uru/front-utils/dist/constants'
import { LOCAL_ROUTES } from '../../constants/routes'
import { constructUrl, getParamFromUrl } from '@san4uru/front-utils/dist/utils'
import { navTo } from '../../utils/html'

class BrandCollections extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isLoading: true, textSearch: '' }
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.props.clearCurrentBrandState()
  }

  loadData = () => {
    this.setState({ isLoading: true })
    this.props
      .fetchExactBrand(this.props.brandId)
      .then(() => this.setState({ isLoading: false }))
  }

  onReload = () => {
    this.props.clearCurrentBrandState()
    this.loadData()
  }

  onRemove = (id) => {
    this.props.deleteBrandCollection({ id }).then(this.onReload)
  }

  render() {
    const { collections = [], url, name } = this.props.brand
    const { isLoading, textSearch } = this.state

    if (!collections.length && !isLoading) {
      return (
        <div className="brand-collection-creator">
          <PageHeaderActions
            onAdd={() =>
              navTo({
                pathname: LOCAL_ROUTES.BRANDS_CREATE_COLLECTION,
                params: { brand_id: url },
              })
            }
            onReload={this.onReload}
          />
          <Title>Коллекции бренда {name}</Title>
          {textSearch && (
            <SearchInput
              onSearch={this.onSearch}
              initialValue={textSearch}
              onChange={(textSearch) => this.setState({ textSearch })}
            />
          )}
          <EmptySection className="empty-screen" />
        </div>
      )
    }

    return (
      <div className="brand-collection-creator">
        <PageHeaderActions
          onAdd={() =>
            navTo({
              pathname: LOCAL_ROUTES.BRANDS_CREATE_COLLECTION,
              params: { brand_id: url },
            })
          }
          onReload={this.onReload}
        />
        <Title>Коллекции бренда {name}</Title>
        <SearchInput
          onSearch={() => {}}
          initialValue={textSearch}
          onChange={(textSearch) => this.setState({ textSearch })}
        />
        <div className="brand-collection-creator__content">
          {collections
            .filter(({ name }) =>
              textSearch ? name.includes(textSearch) : true
            )
            .map(({ id, name, pdf, catalogs, active }) => (
              <ListItem
                key={id}
                logo={
                  catalogs.length === 1
                    ? catalogs[0][0] && catalogs[0][0].url
                    : catalogs[1][0] && catalogs[1][0].url
                }
                content={
                  <React.Fragment>
                    <span>
                      <b>Название:</b> {name}
                    </span>
                    <div>
                      <b>Каталог:</b>{' '}
                      <a href={pdf} target="_blank" rel="noopener noreferrer">
                        {pdf}
                      </a>
                    </div>
                    {!active ? (
                      <React.Fragment>
                        <br />
                        <span className="text-warning">
                          <b>Не отображается на сайте</b>
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                }
                itemLink={constructUrl({
                  pathname: ROUTES.BRAND_COLLECTION.replace(':id', id),
                  params: { brand: url },
                })}
                onEdit={() =>
                  navTo({
                    pathname: LOCAL_ROUTES.BRANDS_EDIT_COLLECTION,
                    params: { brand_id: url, collection_id: id },
                  })
                }
                onRemove={() => this.onRemove(id)}
              />
            ))}
        </div>
        {isLoading && <Loader />}
      </div>
    )
  }
}

const mapStateToProps = ({ brands }) => ({
  brand: brands.current,
  brandId: getParamFromUrl('brand_id'),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      fetchExactBrand,
      clearCurrentBrandState,
      deleteBrandCollection,
    },
    dispatch
  )
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandCollections)
)
