import React from 'react'
import './styles.css'

export class ErrorDescriptionType {
  static SameContent = 'SameContent'
  static EmptyFields = 'EmptyFields'
  static IncorrectFields = 'IncorrectFields'
}

export const ErrorDescriptionTextByType = {
  [ErrorDescriptionType.SameContent]: `Текущее содержание полей совпадает с информацией, указанной на сайте. Для сохранения результатов редактирования необходимо изменить одно из полей`,
  [ErrorDescriptionType.EmptyFields]: 'Следующие обязательные поля пусты: ',
  [ErrorDescriptionType.IncorrectFields]: 'Следующие поля некорректны: ',
}

export const fieldNameBySelectorName = {
  description: 'Описание',
  name: 'Название',
  logo: 'Логотип',
  aspects: 'Категория',
  aspect: 'Категория',
  type: 'Тип товара',
  category: 'Категория товара',
  article: 'Артикул',
  author: 'Автор',
  brand: 'Бренд',
  images: 'Изображения'
}

const ErrorDescription = ({ type, fields = [] }) => {
  if (type === ErrorDescriptionType.SameContent) {
    return (
      <div className="error-description">
        {ErrorDescriptionTextByType[type]}
      </div>
    )
  }

  if (
    type === ErrorDescriptionType.EmptyFields ||
    type === ErrorDescriptionType.IncorrectFields
  ) {
    return (
      <div className="error-description">
        <b>{ErrorDescriptionTextByType[type]}</b>
        {fields
          .map((f) => fieldNameBySelectorName[f])
          .filter(Boolean)
          .join(', ')}
      </div>
    )
  }

  return null
}

export default ErrorDescription
