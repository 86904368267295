import React from 'react'
import './styles.css'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Button } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { uploadImage, uploadPdf } from '../../modules/attachments'
import mitt from 'mitt'
import { FileType } from './FileType'
import { ImageInput } from './ImageInput'
import { PdfInput } from './PdfInput'
import { addAlert, AlertType, removeAlert } from '../../modules/global'

const EVENTS = {
  READY: 'READY',
  FILE_LOADED: 'FILE_LOADED',
}

class UploadAttachmentButton extends React.Component {
  fileType = FileType.Image

  constructor(props) {
    super(props)

    if (props.fileType) {
      this.fileType = props.fileType
    }

    this.state = {
      filesMeta: {},
      files: {},
    }
    this.emitter = mitt()
    this.emitter.on(EVENTS.FILE_LOADED, this.onFileLoaded)
  }

  componentWillUnmount() {
    this.emitter.off(EVENTS.FILE_LOADED, this.onFileLoaded)
  }

  onFileLoaded = ({ file, fileMeta }) => {
    const action =
      this.fileType === FileType.Image
        ? this.props.uploadImage
        : this.props.uploadPdf

    action({ file: fileMeta, name: file.name }).then((file) => {
      if (!file.error) {
        const { url, name } = file
        this.props.onFileUploadChange({ url, name })
      }
    })
  }

  getElementId = () => {
    const { elementUid } = this.props

    return elementUid ? `file_upload-${elementUid}` : 'file_upload'
  }

  onFileUploadClick = () => {
    document.getElementById(this.getElementId()).click()
  }

  onChange = (e) => {
    const targetFiles = [...e.target?.files] || []
    const files = this.props.multiple ? targetFiles : [targetFiles[0]]
    files.forEach((file, index) => this.loadFile(file, index))
  }

  loadFile = async (file, index = 0) => {
    if (!file) {
      return
    }

    const alertId = await this.showUploadingAlert(file.name)

    this.setState(({ filesMeta }) => ({
      filesMeta: {
        ...filesMeta,
        [index]: {
          loading: true,
        },
      },
    }))

    const reader = new FileReader()
    reader.onloadend = () => {
      const fileMeta = reader.result
      this.setState(({ files, filesMeta }) => ({
        filesMeta: {
          ...filesMeta,
          [index]: fileMeta,
        },
        files: {
          ...files,
          [index]: file,
        },
      }))
      this.emitter.emit(EVENTS.FILE_LOADED, { file, fileMeta, index })
      this.removeUploadingAlertById(alertId)
    }
    reader.readAsDataURL(file)
  }

  showUploadingAlert = async (fileName) => {
    if (this.fileType === FileType.Pdf) {
      return this.props.addAlert({
        title: 'Идет загрузка файла...',
        text: fileName || '',
        type: AlertType.Info,
      })
    }

    return this.props.addAlert({
      title: 'Идет загрузка изображения...',
      text: fileName || '',
      type: AlertType.Info,
    })
  }

  removeUploadingAlertById = (id) => {
    id && this.props.removeAlert({ created: id })
  }

  renderInputButton = () => {
    const { multiple, label } = this.props

    if (this.fileType === FileType.Pdf) {
      return (
        <PdfInput
          id={this.getElementId()}
          onChange={this.onChange}
          label={label || 'Загрузить PDF'}
        />
      )
    }

    return (
      <ImageInput
        id={this.getElementId()}
        multiple={multiple}
        label={label || 'Загрузить изображение'}
        onChange={this.onChange}
      />
    )
  }

  render() {
    return (
      <Button
        variant="contained"
        color="default"
        className="upload-attachment-button"
        startIcon={<CloudUploadIcon />}
        onClick={this.onFileUploadClick}>
        {this.renderInputButton()}
      </Button>
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { uploadImage, uploadPdf, addAlert, removeAlert },
    dispatch
  )
export default connect(null, mapDispatchToProps)(UploadAttachmentButton)
