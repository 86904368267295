import transport from './transport'

export const fetchItems = (params) =>
  transport.get('/api/admin/items/', { params })

export const fetchExactItem = (params) =>
  transport.get('/api/admin/item/', { params })

export const createItem = ({ ...rest }) =>
  transport.post('/api/admin/items/add/', rest)

export const editItem = ({ ...rest }) =>
  transport.post('/api/admin/items/edit/', rest)

export const convertPrice = ({ currency, price }) =>
  transport.post('/api/items/convert/', { currency, price })

export const deleteItem = (rest) =>
  transport.post('/api/admin/items/delete/', rest)
